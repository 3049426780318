import Vue from "vue";
import moment from "moment";
import _ from "lodash";
import store from "./store";
// import axios from "axios";
// import config from "../config";
var numeral = require("numeral");

Vue.filter("lowerCase", function(val) {
  return val.toLowerCase();
});
Vue.filter("formatPurcent", function(val) {
  return numeral((val * 1) / 100).format("0,0 %") + "";
});
Vue.filter("formatFromTo", function(valFrom, valTo) {
  let mF = moment(new Date(valFrom));
  let mT = moment(new Date(valTo));
  return "De " + mF.format("HH[H]mm") + " à " + mT.format("HH[H]mm");
});
Vue.filter("formatDate", function(val) {
  let v = "";
  if (_.isString(val)) {
    let m = moment(val);
    if (m.isValid()) v = m.format("DD/MM/YYYY");
  }
  if (moment.isMoment(val)) v = val.format("DD/MM/YYYY");
  else if (moment.isDate(val)) v = moment(val).format("DD/MM/YYYY");
  return v;
});
Vue.filter("formatDateTime", function(val) {
  let v = "";
  if (_.isString(val)) {
    let m = moment(val);
    if (m.isValid()) v = "Le " + m.format("DD/MM/YYYY [à] HH[H]mm");
  }
  if (moment.isMoment(val)) v = "Le " + val.format("DD/MM/YYYY [à] HH[H]mm");
  if (moment.isDate(val)) v = "Le " + moment(val).format("DD/MM/YYYY [à] HH[H]mm");
  return v;
});
Vue.filter("formatDateAgo", function(val) {
  let v = "";
  if (_.isString(val)) {
    let m = moment(val);
    if (m.isValid()) v = m.fromNow();
  }
  if (moment.isMoment(val)) v = val.fromNow();
  if (moment.isDate(val)) v = moment(val).fromNow();
  return v;
});
Vue.filter("formatPrice", function(val, suffixe) {
  if (!suffixe) suffixe = " €";
  val = val * 1;
  if (val >= 1000000) return numeral(val / 1000000).format("0,0.0") + "M" + suffixe;
  if (val >= 1000) return numeral(val / 1000).format("0,0.0") + "K" + suffixe;
  return numeral(val).format("0,0") + suffixe;
});
Vue.filter("formatSurface", function(val, whithoutSup) {
  let more = " m<sup>2</sup>";
  if (whithoutSup) more = "m2";
  return numeral(val * 1).format("0,0") + more;
});
Vue.filter("formatYesNo", function(val) {
  return val ? "Oui" : "Non";
});
Vue.filter("formatFromArray", function(val, myarray, mydefault) {
  let f = _.result(_.find(myarray, { value: val }), "text", mydefault);
  return f;
});

Vue.filter("contactIcon", function(row_co) {
  let ico = "mdi-account";
  if (row_co.co_type !== "contact") ico = "mdi-account-card-details";
  else if (row_co.co_issyndic) ico = "mdi-crane";
  else if (row_co.co_isrenter) ico = "mdi-owl";
  else if (row_co.co_isowner) ico = "mdi-castle";
  else if (row_co.co_isagency) ico = "mdi-home-thermometer";
  else if (row_co.co_isagent) ico = "mdi-account-key";
  else if (row_co.co_issociety) ico = "mdi-domain";
  return ico;
});

Vue.filter("formatBoolean", function(value) {
  let txt = "";
  if (value) txt = "Oui";
  else txt = "Non";
  return txt;
});
Vue.filter("formatStatusTicketsFreshdesk", function(status) {
  // console.log("status", status);
  let statusTxt = "";
  if (status === 2) statusTxt = "Ouvert";
  if (status === 3) statusTxt = "En attente";
  if (status === 4) statusTxt = "Résolu";
  if (status === 5) statusTxt = "Fermé";
  return statusTxt;
});
Vue.filter("formatUserName", function(user, prefixtxt = "us_") {
  if (!user) return;
  let prefix = prefixtxt ? prefixtxt : "co_invoice_";
  /*   if (contact.co_invoice_name) prefix = "co_invoice_";
  if (contact.us_name) prefix = "us_";
  if (contact.en_name) prefix = "en_"; */
  if (!user[prefix + "name"]) return;
  return user[prefix + "name"].toUpperCase() + " " + user[prefix + "firstname"].charAt(0).toUpperCase() + user[prefix + "firstname"].substr(1);
});
Vue.filter("formatEndUserName", function(enduser, prefixtxt = "us_") {
  if (!enduser) return;
  return enduser.en_fullname + " (N°" + enduser.en_id + ")";
});
Vue.filter("formatContactNameSimple", function(contact, prefixtxt, addCivility = true) {
  if (!contact) return;

  let prefix = prefixtxt ? prefixtxt : "co_invoice_";
  /*   if (contact.co_invoice_name) prefix = "co_invoice_";
  if (contact.us_name) prefix = "us_";
  if (contact.en_name) prefix = "en_"; */
  if (!contact[prefix + "name"]) {
    if (prefixtxt == "co_invoice_") return contact.co_login;
    return "-";
  }
  let civility = contact[prefix + "civility"] ? contact[prefix + "civility"] + " " : "";
  if (!addCivility) civility = "";
  return (
    civility +
    contact[prefix + "firstname"].charAt(0) +
    contact[prefix + "firstname"].substr(1) +
    " " +
    contact[prefix + "name"]
  );
});
Vue.filter("formatContactName", function(row_co, useBold = false, blackSociety = false) {
  if (_.isPlainObject(useBold)) useBold = false;
  if (!row_co) return "";
  let txt = [];
  if (row_co.co_name) txt.push((useBold ? "<b>" : "") + row_co.co_name + (useBold ? "</b>" : ""));
  if (row_co.co_firstname) txt.push(row_co.co_firstname);
  row_co.completeName = txt.join(" ");
  let res = row_co.completeName;
  if ((row_co.co_issociety || row_co.co_isagency || row_co.co_issyndic) && blackSociety) {
    res = `<span class='span-society'>${res}</span>`;
  }
  if (row_co.co_type != "contact" && blackSociety) {
    res = `<span class='span-user'>${res}</span>`;
  }
  return res;
});
Vue.filter("formatContactType", function(row_co) {
  if (!row_co) return "";

  let res = "";
  switch (row_co.co_type) {
    case "admin":
      res = "Admnistrateur";
      break;
    case "apas":
      res = "APAS";
      break;
    case "association":
      res = "Club sportif";
      break;
    case "docgeneral":
      res = "Médecin généraliste";
      break;
    case "docmouveal":
      res = "Médecin Mouveal";
      break;
  }

  return res;
});
Vue.filter("formatvalue", function(value, typeToFindIn, lowercase, plurial) {
  if (!store.state[typeToFindIn]) return "";
  if (!value) return "";
  let text;
  text = _.result(_.find(store.state[typeToFindIn], { value: value }), "text", "erreur");
  if (lowercase === "lowercase") {
    text = text.toLowerCase();
  }
  if (plurial === "plurial") {
    text = text + "s";
  }
  return text;
});
Vue.filter("formatvaluecontact", function(typeToFindIn, value, lowercase, plurial) {
  // console.log("typeToFindIn, value", typeToFindIn, value);
  if (!store.state[typeToFindIn]) return "";
  if (!value) return "";
  let text;
  if (value !== "adminall") {
    text = _.result(_.find(store.state[typeToFindIn], { riro_id: value }), "riro_label", "erreur");
  } else {
    text = "Administrateur";
  }
  if (lowercase === "lowercase") {
    text = text.toLowerCase();
    if (value === "docmouveal") text = "médecin Mouveal";
  }
  if (plurial === "plurial") {
    text = text + "s";
    if (value === "docmouveal" || value === "docgeneral") text = text.split(" ").join("s ");
  }
  if (value === "apas") text = "APAS";
  return text;
});
Vue.filter("formatActionName", function(row_ac) {
  if (!row_ac) return "";
  let txt = [];
  if (row_ac.createdAt) txt.push(moment(row_ac.createdAt).format("DD/MM/YYYY HH[H]mm"));
  //if (row_co) txt.push(Vue.filter("formatContactName")(row_co.data.data));
  if (row_ac.ac_comment) txt.push(row_ac.ac_comment.slice(0, row_ac.ac_comment.length > 40 ? row_ac.ac_comment.length : 40) + "...");
  return txt.join(" / ");
});

Vue.filter("formatEmail", function(txt) {
  return `<a href="mailto:${txt}">${txt}</a>`;
});
Vue.filter("formatEventName", function(row_ve) {
  // console.log("row_ve", row_ve);
  let txt = "";
  txt +=
    "Du " +
    moment(row_ve.ve_dtstart).format("DD/MM/YYYY [à] HH[H]mm") +
    " au " +
    moment(row_ve.ve_dtend).format("DD/MM/YYYY [à] HH[H]mm") +
    " : " +
    row_ve.ve_summary;
  return txt;
});

Vue.filter("formatTel", function(txt) {
  return `<a href="tel:${txt}">${txt}</a>`;
});
Vue.filter("formatWeb", function(txt) {
  return `<a href="${txt}" target="_blank">${txt}</a>`;
});

Vue.filter("nl2br", function(str) {
  let breakTag = "<br />";
  return (str + "").replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1" + breakTag + "$2");
});

Vue.filter("formatContactAdress", function(row_co, sep) {
  if (!sep) sep = "<br>";
  let txt = [];
  if (row_co.co_address1) txt.push(row_co.co_address1);
  if (row_co.co_address2) txt.push(row_co.co_address2);
  if (row_co.co_address3) txt.push(row_co.co_address3);
  if (row_co.co_zip && row_co.co_city) txt.push(row_co.co_zip + " " + row_co.co_city);
  if (row_co.co_country) txt.push(row_co.co_country);
  return `<a href="https://www.google.fr/maps?q=${encodeURI(txt.join(", "))}" target="_blank">${txt.join(sep)}</a>`;
});

Vue.filter("formatBuildingAddress", function(row_bu, sep, usemore = true) {
  if (!row_bu) return "";
  if (!sep) sep = "<br>";
  let txt = [];
  if (row_bu.bu_name) txt.push(row_bu.bu_name);
  if (row_bu.bu_address1) txt.push(row_bu.bu_address1);
  if (row_bu.bu_address2) txt.push(row_bu.bu_address2);
  if (row_bu.bu_address3) txt.push(row_bu.bu_address3);
  let more = "";
  if (row_bu.bu_zip) more += row_bu.bu_zip;
  if (row_bu.bu_zip && row_bu.bu_city) more += " ";
  if (row_bu.bu_city) more += row_bu.bu_city;
  if (row_bu.bu_country) more += (more ? " - " : "") + row_bu.bu_country;
  if (more && usemore) txt.push(more);
  // return `<a href="https://www.google.fr/maps?q=${encodeURI(txt.join(", "))}" target="_blank">${txt.join(sep)}</a>`;
  return `${txt.join(sep)}`;
});

Vue.filter("plural", function(nb, singular, plural) {
  let res = nb + "";
  if (!singular) return res;
  else if (nb > 1 && plural && plural.indexOf("%n") >= 0) res = plural.replace(/%n/g, nb + "");
  else if (nb > 1 && plural && plural.indexOf("%n") < 0) res = nb + " " + plural;
  else if (nb > 1 && !plural) res = nb + " " + singular + "s";
  else if (nb <= 1 && singular.indexOf("%n") >= 0) res = singular.replace(/%n/g, nb + "");
  else if (nb <= 1 && singular.indexOf("%n") < 0) res = nb + " " + singular;
  return res;
});
