<template>
  <section class="w-100">
    <div class="" id="fiche">
      <div class="d-flex flex-row flex-wrap justify-content-between align-items-center">
        <div class="d-flex flex-row align-items-center">
          <h1>
            Utilisateur final N°{{ row_en.en_id }}
            <span v-if="!row_en.bo_id"> (Pas encore de box) </span>
            <span style="color: lightgray">|</span>
            {{ row_co | formatContactNameSimple("co_invoice_") }}
            <span style="color: lightgray">|</span>&nbsp; <span class="text-red-emotivi">{{ row_co.es_id ? row_co.es_id.es_name : "" }}</span
            >&nbsp;
            <span style="color: lightgray">|</span>
          </h1>
          <h4
            :class="{
              'color-emotivi-blue': true,
              'ml-2': true,
              mygreen: row_en.bo_id && $store.state.connectedBoxes.indexOf(row_en.bo_id.bo_mac_address) >= 0,
              myred: !row_en.bo_id || $store.state.connectedBoxes.indexOf(row_en.bo_id.bo_mac_address) < 0
            }"
          >
            {{ row_en.bo_id && $store.state.connectedBoxes.indexOf(row_en.bo_id.bo_mac_address) >= 0 ? "Box connectée" : "Box non connectée" }}
          </h4>
        </div>
        <!-- <div class="d-flex flex-column infComVsTech">
          <m-form-checkbox
            label="Informations commerciales"
            :name="$Utils.randomstring('filterInfoCom')"
            v-model="filterInfoCom"
            class="checkbox"
          ></m-form-checkbox>
          <m-form-checkbox label="Informations techniques" v-model="filterInfoTech" :name="$Utils.randomstring('filterInfoTech')"></m-form-checkbox>
        </div> -->
        <div v-if="row_en.newMessage > 0">
          <span
            style="
              margin-left: 20px;
              background-color: green;
              color: white;
              font-weight: bold;
              border-radius: 50%;
              width: 25px;
              display: inline-block;
              text-align: center;
              height: 25px;
            "
            >{{ row_en.newMessage }}</span
          >
          Vous avez reçu {{ row_en.newMessage }} nouveau(x) messages(s) aujourd'hui.
        </div>

        <button class="btn btn-sm btn-primary">
          <router-link class="color-white" to="/boxes"> Retour liste</router-link>
        </button>
      </div>
      <!--       <div class="row d-flex flex-row align-items-end">
        <div class="col-md-9 d-flex flex-row align-items-center justify-content-between mb-2">
          <div class="d-flex flex-row align-items-center">
            <h1>
              {{ row_en.bo_id ? "Box n° " + row_en.bo_id.bo_id : "Pas encore de box" }} -
              {{ row_en.co_id | formatContactNameSimple("co_invoice_") }} - {{ row_en.co_id.es_id ? row_en.co_id.es_id.es_name : "" }} -
            </h1>
            <h4 class="color-emotivi-blue ml-2">{{ row_en.infos.connected ? "Box connectée" : "Box non connectée" }}</h4>
          </div>
          <button class="btn btn-primary "><router-link class="color-white" to="/boxes"> Retour liste </router-link></button>
        </div>
        <div class="col-md-3">
          <m-form-checkbox label="Informations commerciales" :name="$Utils.randomstring('info_com')" v-model="filterInfoCom"></m-form-checkbox>
          <m-form-checkbox label="Informations techniques" v-model="filterInfoTech" :name="$Utils.randomstring('info_tech')"></m-form-checkbox>
        </div>
      </div> -->
      <div v-show="row_en.en_state_subscription === 'SUSPENDED'" class="row mt-5 mb-5">
        <div class="col-md-9 d-flex align-items-center">
          <icon class="mr-4" scale="3" style="fill: orange" name="exclamation-triangle"></icon>
          <h3 class="text-warning font-weight-bold">Service suspendu</h3>
        </div>
      </div>
      <div v-if="filterInfoCom">
        <div class="row">
          <div class="col-md-9">
            <h3 class="">Informations commerciales</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-9">
            <!-- CONTACTS CARD  -->
            <div v-if="$store.getters.checkUserAccess($router, '/contacts')" class="card">
              <div class="d-flex flex-row flex-wrap justify-content-between">
                <h6>
                  <span v-show="$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'" class="number-title">1</span
                  >Contact (compte payeur)
                </h6>
                <button v-if="$store.getters.checkUserAccess($router, '/contacts/:co_id/edit')" class="btn btn-primary" @click="editContact">
                  Modifier
                </button>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <h5>Coordonnées</h5>
                  <div>
                    Téléphone mobile : <span class="color-emotivi-blue">{{ row_co.co_invoice_tel }}</span>
                  </div>
                  <div>
                    Identifiant web : <span class="color-emotivi-blue">{{ row_co.co_login }}</span>
                  </div>
                  <div>
                    Email :
                    <a :href="`mailto:${row_co.co_invoice_email}`">{{ row_co.co_invoice_email }}</a>
                  </div>
                  <div class="mt-3">Établissement : {{ row_co.es_id ? row_co.es_id.es_name : "" }}</div>
                  <div v-if="row_co.afco_id && row_co.afco_id.af_id">Code d'affiliation : {{ row_co.afco_id.af_id.af_code }}</div>
                  <div v-if="row_co.afco_id && row_co.afco_id.af_id">Nom du revendeur : {{ row_co.afco_id.af_id.af_name }}</div>
                  <div>Site partenaire : {{ row_co.co_partner_site ? row_co.co_partner_site : "" }}</div>
                  <div>Commentaire : {{ row_co.co_comment ? row_co.co_comment : "" }}</div>
                </div>
                <div class="col-md-4">
                  <h5>Adresse de facturation</h5>
                  <div>
                    <a href="javascript:void(0)" @click="$router.push('/contacts/' + row_co.co_id + '/details')">{{
                      row_co_invoice | formatContactNameSimple("co_invoice_")
                    }}</a>
                  </div>
                  <div>
                    <a :href="`https://maps.google.fr?q=${co_invoice_address}`" target="_blank">
                      {{ row_co.co_invoice_address1 }}<br />
                      <span v-if="row_co.co_invoice_address2">{{ row_co.co_invoice_address2 }}<br /></span>
                      {{ row_co.co_invoice_zip }} {{ row_co.co_invoice_city }}<br />
                    </a>
                  </div>
                  <h5 v-if="$store.getters.checkUserAccess($router, '/invoices')" class="mt-2">Prélévement bancaire</h5>
                  <div v-if="$store.getters.checkUserAccess($router, '/invoices') && row_co.co_rib">
                    <div>Titulaire : {{ row_co.co_rib_name }}</div>
                    <div>IBAN : {{ row_co.co_rib_iban }}</div>
                    <div>BIC : {{ row_co.co_rib_bic }}</div>
                  </div>
                  <div v-else-if="activePayment && activePayment.pm_method === 'rib' && activePayment.pm_gocardless_ids">
                    Prélévement bancaire auprès de GOCARDLESS
                  </div>
                  <div v-else>Aucun</div>
                </div>
                <div class="col-md-4">
                  <h5>Adresse de livraison</h5>
                  <div v-show="row_co.co_shipping_society_name">Nom de la société : {{ row_co.co_shipping_society_name }}</div>
                  <div>{{ row_co | formatContactNameSimple("co_shipping_") }}</div>
                  <div>
                    <a :href="`https://maps.google.fr?q=${co_shipping_address}`" target="_blank">
                      {{ row_co.co_shipping_address1 }}<br />
                      <span v-if="row_co.co_shipping_address2">{{ row_co.co_shipping_address2 }}<br /></span>
                      {{ row_co.co_shipping_zip }} {{ row_co.co_shipping_city }}<br />
                    </a>
                  </div>
                  <div class="font-weight-bold text-danger" v-if="row_co.co_colissimo_ref">POINT RELAIS : {{ row_co.co_colissimo_ref }}</div>
                  <div v-if="row_co.es_id && row_co.es_id.es_white_label">
                    <h5 class="mt-2">Fournisseur de services</h5>
                    <div class="row">
                      <div class="col">{{ row_co.es_id.es_name }}</div>
                      <div class="col-auto">
                        <img :src="$config.server_url + row_co.es_id.es_avatar" alt="logo fournisseur" width="50px" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- ORDERS CARD -->
            <div v-if="$store.getters.checkUserAccess($router, '/contacts/:co_id/order')" class="card">
              <div class="d-flex flex-row flex-wrap justify-content-between">
                <h6>
                  <span v-show="$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'" class="number-title">3</span
                  >Paiements
                </h6>
                <p v-if="loanedKit" style="color: #199aaa"><b>Le kit est actuellement en prêt.</b></p>
                <div class="row d-flex flex-row flex-wrap">
                  <button v-if="loanedKit" class="btn btn-warning mr-2 m-1" @click="confirmStopLoan()">Kit restitué</button>
                  <button v-else class="btn btn-primary mr-2 m-1" @click="confirmStartLoan()">Kit en prêt</button>

                  <button
                    class="btn btn-primary mr-2 m-1"
                    v-if="
                      ((this.activePayment && this.activePayment.pm_method === 'cb') || (!this.activePayment && row_co.co_rib == 0)) &&
                      row_en.en_state_subscription !== 'CB' &&
                      row_en.en_state_subscription !== 'WAITING_STOPPED' &&
                      (!this.activeSubscription || (this.activeSubscription && this.activeSubscription.su_paymentmethod !== 'cb'))
                    "
                    @click="confirmStartSubscriptionDate('cb')"
                  >
                    Commencer abonnement CB
                  </button>
                  <button
                    :disabled="disableGocardlessStartSubscriptionButton"
                    class="btn btn-primary mr-2 m-1"
                    v-if="
                      this.activePayment &&
                      this.activePayment.pm_rib == 1 &&
                      this.activePayment.pm_gocardless_ids &&
                      (!this.activeSubscription || (this.activeSubscription && this.activeSubscription.su_state !== 2))
                    "
                    @click="confirmStartSubscriptionDate('gocardless')"
                  >
                    Commencer abonnement RIB (gocardless)
                  </button>
                  <button
                    :disabled="disableGocardlessStartSubscriptionButton"
                    class="btn btn-primary mr-2 m-1"
                    v-else-if="
                      ((this.activePayment && this.activePayment.pm_rib == 1) || (!this.activePayment && row_co.co_rib == 1)) &&
                      row_en.en_state_subscription !== 'RIB' &&
                      row_en.en_state_subscription !== 'WAITING_STOPPED' &&
                      (!this.activeSubscription || (this.activeSubscription && this.activeSubscription.su_paymentmethod !== 'rib'))
                    "
                    @click="confirmStartSubscriptionDate('rib')"
                  >
                    Commencer abonnement RIB
                  </button>
                  <!-- <button
                    class="btn btn-warning mr-2 m-1"
                    :disabled="disableGocardlessStopSubscriptionButton"
                    v-else-if="
                      this.activeSubscription &&
                        this.activeSubscription.subscriptionData &&
                        this.activeSubscription.subscriptionData.su_state === 2 &&
                        this.activeSubscription.subscriptionData.su_gocardlessid
                    "
                    @click="confirmCancelSubscriptionGocardless()"
                  >
                    Arrêter abonnement RIB (Gocardless)
                  </button> -->
                  <!-- <button
                    class="btn btn-warning mr-2 m-1"
                    v-else-if="
                      this.activeSubscription &&
                        this.activeSubscription.subscriptionData &&
                        this.activeSubscription.subscriptionData.su_state === 2 &&
                        !this.activeSubscription.subscriptionData.su_stripeid
                    "
                    @click="confirmCancelSubscriptionRib()"
                  >
                    Arrêter abonnement RIB
                  </button> -->

                  <div
                    v-if="displayConfirmStartSubscriptionDate"
                    class="d-flex justify-content-start align-items-center alert alert-secondary mt-1"
                    style="height: 38px"
                  >
                    <span> Choisir la date de démarrage </span>
                    <div class="ml-2 d-flex align-items-center">
                      <m-form-date ref="newSubscriptionStartDate" v-model="newSubscriptionStartDate"></m-form-date>
                      <button class="btn btn-sm btn-primary mt-1" @click="createSubscriptionStartDate()">Démarrer</button>
                    </div>
                  </div>
                  <button class="btn btn-danger mr-2 m-1" @click="confirmWaitingRefund()">Déclarer en attente de remboursement</button>
                  <!-- <m-form-checkbox
                    class="m-2"
                    label="Envoyer un email d'annulation d'abonnement"
                    :name="$Utils.randomstring('en_send_cancel_sub_email')"
                    v-model="row_en.en_send_cancel_sub_email"
                  ></m-form-checkbox> -->
                </div>
              </div>
              <div>
                <div style="padding: 1rem; border: 1px solid green; border-radius: 0.5rem; display: inline-block" v-if="row_en.en_promo_code">
                  Code promo :
                  <span style="font-weight: bold; color: green">{{ row_en.en_promo_code }}</span>
                </div>
              </div>

              <div class="p-3 mt-3">
                <h5 class="">
                  Les paiements
                  <span v-if="loadingPayments"><icon width="30" height="30" name="sync" class="mr-2 fa-spin"></icon></span>
                </h5>
                <m-list-simple ref="commandelist" :dynamic="true" :items="allCharges" item-value="ac_id" :total="allCharges.length">
                  <template v-slot:ths="{}">
                    <th width="170">
                      <span class="pointer">Date</span>
                    </th>
                    <th width="90">
                      <span class="pointer">Montant</span>
                    </th>
                    <th>
                      <span class="pointer">Statut</span>
                    </th>
                    <th>
                      <span class="pointer">Description</span>
                    </th>
                    <th>
                      <span class="pointer">Id - lien vers le paiement</span>
                    </th>
                    <th>
                      <span class="pointer">Plateforme</span>
                    </th>
                    <th>
                      <span class="pointer"></span>
                    </th>
                    <th>
                      <span class="pointer"></span>
                    </th>
                    <th>
                      <span class="pointer"></span>
                    </th>
                  </template>
                  <template v-slot:tds="{ item }">
                    <td>
                      {{
                        $moment.unix(item.created).isValid()
                          ? $moment.unix(item.created).format("YYYY-MM-DD HH:mm:ss")
                          : $moment(item.created).format("YYYY-MM-DD HH:mm:ss")
                      }}
                    </td>
                    <td>{{ (item.amount / 100) | formatPrice }}</td>

                    <td :style="item.status === 'FAILED' ? 'color:red;' : ''">
                      {{ paymentsStatusText[item.status] ? paymentsStatusText[item.status] : item.status }}
                    </td>
                    <td :style="item.status === 'FAILED' ? 'color:red;' : ''">
                      {{ item.description }}
                    </td>
                    <td>
                      <a :href="item.receipt_url" target="_blank">{{ item.id }}</a>
                    </td>
                    <td>
                      {{ item.platform }}
                    </td>
                    <td>
                      <!--<icon 
                        v-if="item.payment_intent && !item.amount_refunded"
                        @click="confirmCancelCharge(item.payment_intent)"
                        width="22"
                        height="22"
                        name="trash-alt"
                        class="mr-2"
                        color="black"
                      ></icon> -->
                      <icon
                        v-if="item.payment_intent && item.amount_refunded != item.amount"
                        @click="newCancelCharge(item.payment_intent)"
                        width="22"
                        height="22"
                        name="trash-alt"
                        class="mr-2"
                        color="black"
                      ></icon>
                    </td>
                    <td>
                      <span v-if="item.amount_refunded" class="badge badge-danger"
                        >REMBOURSÉ&nbsp;:&nbsp;{{ (item.amount_refunded / 100) | formatPrice }}</span
                      >
                    </td>
                    <td>
                      <span
                        style="color: #91295a; font-weight: bold"
                        v-if="item.end_user && Array.isArray(item.end_user) && item.end_user.length && item.end_user[0].en_id === row_en.en_id"
                        >Caution liée à cette box</span
                      >
                    </td>
                  </template>
                </m-list-simple>
                <!-- <h5 class="">
                  Les paiements en RIB (abonnement mensuel)
                  <span v-if="loadingPayments"><icon width="30" height="30" name="sync" class="mr-2 fa-spin"></icon></span>
                </h5>
                <m-list-simple ref="commandelist" :dynamic="true" :items="gocardlessCharges" item-value="ac_id" :total="gocardlessCharges.length">
                  <template v-slot:ths="{}">
                    <th width="180">
                      <span class="pointer">Date</span>
                    </th>
                    <th width="100">
                      <span class="pointer">Montant</span>
                    </th>
                    <th width="160">
                      <span class="pointer">Statut</span>
                    </th>
                    <th>
                      <span class="pointer">Description</span>
                    </th>
                    <th>
                      <span class="pointer">Id - lien vers gocardless</span>
                    </th>
                  </template>
                  <template v-slot:tds="{ item }">
                    <td>{{ $moment(item.charge_date).format("YYYY-MM-DD HH:mm:ss") }}</td>
                    <td>{{ (item.amount / 100) | formatPrice }}</td>
                    <td>
                      {{ item.status ? paymentsStatusText[item.status] : "" }}
                    </td>
                    <td>
                      {{ item.description }}
                    </td>
                    <td>
                      <a :href="item.url" target="_blank">{{ item.id }}</a>
                    </td>
                  </template>
                </m-list-simple> -->
                <hr style="height: 5px; background-color: lightgrey" />
              </div>

              <div class="p-3">
                <h5 class="">
                  Les abonnements <span v-if="loadingPayments"><icon width="30" height="30" name="sync" class="mr-2 fa-spin"></icon></span>
                </h5>
                <div>
                  <div
                    class="mt-2 ml-2 mb-3 row align-items-center"
                    v-if="activeSubscription && $moment(activeSubscription.su_startdate).isValid() && !activeSubscription.su_active"
                  >
                    <span class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5"
                      ><small
                        >Démarrage de l'abonnement prévu le :
                        <b class="alert alert-info">{{ $moment(activeSubscription.su_startdate).format("L") }}</b></small
                      ></span
                    >
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-7 mt-3 mt-sm-3 mt-md-3 mt-lg-3 mt-xl-0 d-flex">
                      <div class="d-flex justify-content-start align-items-center">
                        <button
                          style="text-overflow: ellipsis; overflow: hidden"
                          class="btn btn-warning btn-sm ml-2 mr-2 m-1"
                          @click="modifySubscriptionStartDateDisplay()"
                        >
                          Modifier la date de démarrage
                        </button>
                        <div class="d-flex align-items-center" v-if="displayModifySubscriptionStartDate">
                          <m-form-date v-model="subscriptionStartDate"></m-form-date>
                          <button class="btn btn-sm btn-primary mt-1" @click="modifySubscriptionStartDate()">Enregistrer</button>
                        </div>
                      </div>
                      <div
                        v-if="
                          !displayModifySubscriptionStartDate &&
                          !activeSubscription.subscriptionData.su_stripeid &&
                          !activeSubscription.subscriptionData.su_gocardlessid
                        "
                      >
                        <button
                          style="text-overflow: ellipsis; overflow: hidden"
                          class="btn btn-sm btn-warning ml-2 mr-2 m-1"
                          @click="updateSubscription({ su_active: true })"
                        >
                          Démarrer l'abonnement immediatement
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    class="mt-2 ml-2 mb-3 row align-items-center"
                    v-else-if="!activeSubscription && row_en.en_startdate_subscription && $moment(row_en.en_startdate_subscription).isValid()"
                  >
                    <span class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                      ><small
                        >Démarrage de l'abonnement prévu le :
                        <b class="alert alert-info">{{ $moment(row_en.en_startdate_subscription).format("L") }}</b></small
                      ></span
                    >
                  </div>
                </div>
                <div v-if="waitingSubscriptionStatus" class="row w-100" style="height: 65px">
                  <v-spinner
                    ref="waitingSpinner"
                    :_display="'flex'"
                    :_text="`Votre demande a bien été prise en compte, la modification de l'abonnement est en cours de traitement, merci de patienter quelques minutes.`"
                    :_backgroundColor="'rgba(255, 255, 255, 0.9)'"
                    :_textColor="'black'"
                    :_spinnerColor="'black'"
                  ></v-spinner>
                </div>
                <div v-if="waitingPaymentMethodStatus" class="row w-100" style="height: 65px">
                  <v-spinner
                    ref="waitingSpinner"
                    :_display="'flex'"
                    :_text="`Votre demande a bien été prise en compte, la modification du mode de paiement est en cours de traitement, merci de patienter quelques minutes.`"
                    :_backgroundColor="'rgba(255, 255, 255, 0.9)'"
                    :_textColor="'black'"
                    :_spinnerColor="'black'"
                  ></v-spinner>
                </div>
                <m-list-simple ref="commandelist" :dynamic="true" :items="allSubscriptions" item-value="ac_id" :total="allSubscriptions.length">
                  <template v-slot:ths="{}">
                    <!-- <th style="width: 75px">Box</th> -->
                    <th style="width: 100px">
                      <span class="pointer">Montant</span>
                    </th>
                    <th>
                      <span class="pointer">Statut</span>
                    </th>
                    <th>
                      <span class="pointer">Description</span>
                    </th>
                    <th>
                      <span class="pointer">Id</span>
                    </th>
                    <th>
                      <span class="pointer">Début abonnement</span>
                    </th>
                    <th>
                      <span class="pointer">Fin abonnement</span>
                    </th>
                    <th>
                      <span class="pointer">Plateforme</span>
                    </th>
                    <!-- <th>
                      <span class="pointer">&nbsp;</span>
                    </th> -->
                  </template>
                  <template v-slot:tds="{ item }">
                    <!-- <td :style="{ 'background-color': item.color }">
                      <a
                        style="color: #91295a; font-weight: bold"
                        @click="$router.push('/boxes/' + item.end_user[0].en_id) && $router.go()"
                        v-if="item.end_user && Array.isArray(item.end_user) && item.end_user.length"
                        >Box {{ item.end_user[0].en_id }}</a
                      >
                    </td> -->
                    <td :style="{ 'background-color': item.color }">{{ (item.plan.amount / 100) | formatPrice }}</td>
                    <td :style="{ 'background-color': item.color }">
                      {{ item.su_state_label }}
                    </td>
                    <td :style="{ 'background-color': item.color }">
                      {{ item.plan && item.plan.of_name ? item.plan.of_name : item.plan ? item.plan.nickname : "" }}
                    </td>
                    <td :style="{ 'background-color': item.color }">
                      {{ item.id }}
                    </td>
                    <td :style="{ 'background-color': item.color }">
                      <span v-if="item.su_startdate">{{ $moment(item.su_startdate).format("DD/MM/YYYY HH:mm:ss") }}</span>
                      <span v-else-if="item.start_date">{{ $moment.unix(item.start_date).format("DD/MM/YYYY HH:mm:ss") }}</span>
                    </td>
                    <td :style="{ 'background-color': item.color }">
                      <span v-if="item.su_enddate">{{ $moment(item.su_enddate).format("DD/MM/YYYY HH:mm:ss") }}</span> <br />
                      <span v-if="item.subscriptionData.su_cancel_at" style="color: red; font-weight: bold"
                        >(Stoppé le {{ $moment(item.subscriptionData.su_cancel_at).format("DD/MM/YYYY HH:mm:ss") }})</span
                      >
                    </td>
                    <td :style="{ 'background-color': item.color }">
                      {{
                        item.subscriptionData && item.subscriptionData.su_gocardlessid
                          ? "GOCARDLESS"
                          : item.subscriptionData && item.subscriptionData.su_stripeid
                          ? "STRIPE"
                          : "BANQUE"
                      }}
                    </td>
                    <td :style="{ 'background-color': item.color }">
                      <icon
                        v-if="item.typeproblem"
                        name="exclamation-triangle"
                        scale="1"
                        class="mr-2"
                        width="22"
                        color="red"
                        height="22"
                        :data-popover="item.typeproblem"
                      ></icon>

                      <icon
                        v-if="
                          item.id &&
                          typeof item.id === 'string' &&
                          item.id.indexOf('sub_') >= 0
                        "
                        @click="confirmCancelSubscription(item.id)"
                        width="22"
                        height="22"
                        name="trash-alt"
                        class="mr-2"
                        color="black"
                        title="Arrêter l'abonnement Stripe"
                      ></icon>

                      <icon
                        v-else-if="
                          item.id &&
                          item.su_paymentmethod === 'rib' &&
                          item.su_state !== 0 &&
                          item.subscriptionData &&
                          item.subscriptionData.su_gocardlessid
                        "
                        @click="confirmCancelSubscriptionGocardless(item.id, item)"
                        width="22"
                        height="22"
                        name="trash-alt"
                        class="mr-2"
                        color="black"
                        :disabled="disableGocardlessStopSubscriptionButton"
                        title="Arrêter l'abonnement GoCardless"
                      ></icon>

                      <icon
                        v-else-if="
                          item.id &&
                          item.su_paymentmethod === 'rib' &&
                          item.su_state !== 0 &&
                          item.subscriptionData &&
                          !item.subscriptionData.su_stripeid &&
                          !item.subscriptionData.su_gocardlessid
                        "
                        @click="confirmCancelSubscriptionRib(item.id, item)"
                        width="22"
                        height="22"
                        name="trash-alt"
                        class="mr-2"
                        color="black"
                        title="Arrêter l'abonnement SEPA"
                      ></icon>

                      <icon
                        v-else-if="
                          item.id && 
                          (item.su_paymentmethod === 'cb' || item.su_paymentmethod === 'rib') && 
                          item.su_state === 2 &&
                          item.subscriptionData &&
                          item.subscriptionData.su_stripeid &&
                          !item.subscriptionData.su_gocardlessid
                        "
                        @click="confirmCancelSubscription(item.id)"
                        width="22"
                        height="22"
                        name="trash-alt"
                        class="mr-2"
                        color="black"
                        title="Arrêter l'abonnement Stripe (CB/SEPA)"
                      ></icon>
                    </td>
                    <!-- <td :style="{ 'background-color': item.color }">
                      <span
                        style="color: #91295a; font-weight: bold"
                        v-if="item.end_user && Array.isArray(item.end_user) && item.end_user.length && item.end_user[0].en_id === row_en.en_id"
                        >Abonnement lié à cette box</span
                      >
                    </td> -->
                  </template>
                </m-list-simple>
                <span v-if="oldRib" style="color: red">L'utilisateur souhaite payer par rib mais n'existe pas dans gocardless. </span>
                <hr style="height: 5px; background-color: lightgrey" />
              </div>
              <div class="p-3">
                <h5 class="">
                  Les modes de paiement
                  <span v-if="loadingPayments"><icon width="30" height="30" name="sync" class="mr-2 fa-spin"></icon></span>
                </h5>
                <button
                  class="mt-3 btn btn-primary"
                  :disabled="disableGocardlessButton"
                  v-if="
                    this.activePayment &&
                    this.activePayment.pm_rib == 1 &&
                    this.activePayment.pm_gocardless_ids &&
                    this.activePayment.pm_gocardless_ids.customer
                  "
                  @click="saveIBANToGocardless('update')"
                >
                  Mettre à jour l'IBAN sur Gocardless
                </button>
                <button
                  :disabled="disableGocardlessButton"
                  class="mt-3 btn btn-primary"
                  v-else-if="(this.activePayment && this.activePayment.pm_rib == 1) || row_co.co_rib == 1"
                  @click="saveIBANToGocardless('create')"
                >
                  Enregistrer l'IBAN sur Gocardless
                </button>

                <m-list-simple
                  v-if="paymentMethods.length"
                  ref="commandelist"
                  :dynamic="true"
                  :items="paymentMethods"
                  item-value="ac_id"
                  :total="paymentMethods.length"
                >
                  <template v-slot:ths="{}">
                    <th width="200px">
                      <span class="pointer">Date</span>
                    </th>
                    <th width="200px">
                      <span class="pointer">Nom</span>
                    </th>
                    <th width="200px">
                      <span class="pointer">Mode</span>
                    </th>
                    <th width="500px">
                      <span class="pointer">Statut</span>
                    </th>
                    <th>
                      <span class="pointer">Type</span>
                    </th>
                    <th>
                      <span class="pointer">Actions</span>
                    </th>
                  </template>
                  <template v-slot:tds="{ item }">
                    <td :style="{ 'background-color': item.color }">
                      {{ $moment(item.createdAt).isValid() ? $moment(item.createdAt).format("DD/MM/YYYY HH:mm:ss") : "" }}
                    </td>
                    <td :style="{ 'background-color': item.color }">{{ item.pm_name }}</td>
                    <td :style="{ 'background-color': item.color }">
                      {{ item.pm_gocardless_ids 
                        ? paymentMethodsName[item.pm_method] + " - GoCardless"
                        : (item.pm_stripe_ids 
                          ? paymentMethodsName[item.pm_method] + " - Stripe"
                          : paymentMethodsName[item.pm_method] + " - Banque") }}
                    </td>
                    <td :style="{ 'background-color': item.color }">
                      {{ displayPaymentMethodStatus(item) }}
                    </td>
                    <td :style="{ 'background-color': item.color }">
                      {{ item.subscription ? "Abonnement N° " + item.subscription : item.caution ? "Caution/Frais de dossier" : "" }}
                    </td>

                    <td :style="{ 'background-color': item.color }">
                      <div class="ml-2" @click="displayPaymentMethodDetails(item, $event)">
                        <icon name="info-circle"></icon>
                      </div>
                    </td>
                  </template>
                </m-list-simple>
                <hr style="height: 5px; background-color: lightgrey" />
              </div>
              <div class="p-3">
                <div class="row">
                  <h5 class="col-8">
                    Les attestations fiscales <span v-if="loadingStripe"><icon width="30" height="30" name="sync" class="mr-2 fa-spin"></icon></span>
                  </h5>
                  <!-- button a droite -->
                  <button class="btn btn-primary col-4" v-if="!loadingStripe" @click="openFormAttestationFiscale">
                    Générer une attestation fiscale
                  </button>
                </div>
                <m-list-simple v-if="attestations.length > 0" ref="commandelist" :dynamic="true" :items="attestations" item-value="fisc_id">
                  <template v-slot:ths="{}">
                    <th width="200px">
                      <span class="pointer">Date de la demande</span>
                    </th>
                    <th>
                      <span class="pointer">Statut</span>
                    </th>
                    <th width="150px">
                      <span class="pointer">Actions</span>
                    </th>
                  </template>
                  <template v-slot:tds="{ item }">
                    <td>{{ item.createdAt }}</td>
                    <td
                      :style="{
                        color: item.fisc_status == '2' ? 'red' : item.fisc_status == '1' ? 'green' : 'orange'
                      }"
                      style="width: 150px"
                    >
                      {{
                        item.fisc_status == "0"
                          ? "En attente"
                          : item.fisc_status == "1"
                          ? "Validée"
                          : item.fisc_status == "2"
                          ? "Irrecevable"
                          : "En attente"
                      }}
                    </td>
                    <td class="d-flex flex-row">
                      <icon @click="openFormAttestationFiscale" width="22" height="22" class="mr-2" name="edit" color="black"></icon>
                    </td>
                  </template>
                </m-list-simple>
                <div v-else>Aucune demande d'attestation fiscale pour ce client.</div>
              </div>
              <div class="p-3">
                <h5 class="">
                  Les factures <span v-if="loadingPayments"><icon width="30" height="30" name="sync" class="mr-2 fa-spin"></icon></span>
                </h5>
                <m-list-simple ref="commandelist" :dynamic="true" :items="orders" item-value="or_id" :total="orders.length">
                  <template v-slot:ths="{}">
                    <th width="200px">
                      <span class="pointer">nom</span>
                    </th>
                    <th width="200px">
                      <span class="pointer">Statut</span>
                    </th>
                    <th>
                      <span class="pointer">Méthode de paiement</span>
                    </th>
                    <th>
                      <span class="pointer">Date</span>
                    </th>
                    <th>
                      <span class="pointer">Total TTC</span>
                    </th>
                    <th>
                      <span class="pointer">Actions</span>
                    </th>
                  </template>
                  <template v-slot:tds="{ item }">
                    <td>{{ item.or_name }}</td>
                    <td>
                      {{ item.or_status }}
                    </td>
                    <td>
                      {{ item.or_paymentmethod }}
                    </td>
                    <td>
                      {{ item.or_date | formatDateTime }}
                    </td>
                    <td>{{ item.or_pricettc / 100 }} €</td>
                    <td class="d-flex flex-row">
                      <router-link :to="'/invoices/' + item.or_id"> <icon color="black" name="edit"></icon></router-link>
                      <div class="ml-2" @click="printOrder(item, $event)">
                        <icon name="print"></icon>
                      </div>
                      <div class="ml-2" @click="updateOrderStatus(item)">
                        <icon v-if="item.or_status === 'UNPAID'" name="euro-sign"></icon>
                        <svg v-else-if="item.or_status === 'PAID'" viewBox="0 0 320 512" width="16" height="16">
                          <g transform="matrix(1, 0, 0, 1, 23.662552, 0.580247)">
                            <path
                              d="M310.7 413.8l8.8 44.5c1.2 6.2-2.6 12.4-8.8 14-12.5 3.2-34.8 7.8-61.1 7.8-104.4 0-182.5-65.3-207.5-155.6h-30.1c-6.6 0-12-5.4-12-12v-28.4c0-6.6 5.4-12 12-12h21.4c-1-13-0.8-28.6 0.2-42.2h-21.6c-6.6 0-12-5.4-12-12v-29.8c0-6.6 5.4-12 12-12h33.1c29-83.7 107.4-144 204.6-144 21.1 0 40.6 2.9 52.7 5.2 6.9 1.3 11.1 8.2 9.3 14.9l-12 44.4c-1.7 6.1-7.8 9.9-14 8.6-9.3-1.9-23.4-4.1-37.9-4.1-54.9 0-97 30-117.6 75h138.3c7.7 0 13.4 7.1 11.7 14.6l-6.5 29.8c-1.1 5.2-6.4 9.4-11.7 9.4v0h-146.6c-1.6 14-1.3 27.9-0.1 42.2h134.2c7.7 0 13.4 7.1 11.7 14.6l-6.4 28.4c-1.2 5.2-6.4 9.4-11.7 9.4h-113.7c19.5 50.7 64.7 85.4 121.5 85.4 18 0 34.7-3 45.4-5.4 6.6-1.5 13.1 2.7 14.4 9.4z"
                            ></path>
                            <path d="M 321.655 21.193 L 6.719 491.051" style="stroke-miterlimit: 2; stroke: rgb(0, 0, 0); stroke-width: 70px"></path>
                          </g>
                        </svg>
                      </div>
                    </td>
                  </template>
                </m-list-simple>
              </div>
            </div>
            <!-- FRESHDESK TICKETS CARD -->
            <div v-if="this.tickets.length" class="card">
              <h6>
                <span class="number-title">6</span>Tickets Freshdesk
                <span v-if="loadingTickets"><icon width="30" height="30" name="sync" class="mr-2 fa-spin"></icon></span>
              </h6>
              <div class="row mt-2">
                <div class="col-md-12">
                  <m-list-simple ref="commandelist" :dynamic="true" :items="tickets" item-value="id" :total="1" @itemclick="goFreshdeskTicket">
                    <template v-slot:ths="{}">
                      <th>
                        <span class="pointer">Date</span>
                      </th>
                      <th>
                        <span class="pointer">Sujet</span>
                      </th>
                      <th class="">
                        <span class="pointer">État</span>
                      </th>
                    </template>
                    <template v-slot:tds="{ item }">
                      <td>{{ item.created_at | formatDateTime }}</td>
                      <td>
                        {{ item.subject }}
                      </td>
                      <td>
                        <div class="ticket-status" :class="'status-' + item.status">
                          {{ item.status | formatStatusTicketsFreshdesk }}
                        </div>
                      </td>
                    </template>
                  </m-list-simple>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <!-- END USERS CARD -->
            <div class="card">
              <div class="d-flex flex-row flex-wrap justify-content-between">
                <h6>
                  <span v-show="$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'" class="number-title">2</span
                  >Utilisateur final
                </h6>
                <div>
                  <button class="btn btn-primary mb-2" @click="editEnduser">Modifier</button>
                </div>
              </div>
              <!-- <div class="bis uppercase">Identifiant Webapp</div> -->
              <div>
                <div v-if="row_en.en_linkello">
                  <img class="logo-linkello" src="/images/linkello.png" /><span class="linkello">Associé à Linkello</span>
                </div>
                Nom complet : {{ row_en.en_fullname }}
                <hr class="hr-mini" />
                <div class="float-right">
                  <button class="btn btn-warning btn-sm mt-2" @click="sendInvitationEmail">Renvoyer</button>
                </div>
                Ident. webapp :
                <span class="color-emotivi-blue" @click="copieClipboard(row_en.en_webapp_login)">{{ row_en.en_webapp_login }}</span
                ><br />
                Pass webapp :<span class="color-emotivi-blue" @click="copieClipboard(row_en.en_webapp_password)">
                  {{ row_en.en_webapp_password }}</span
                >
                <hr class="hr-mini" />
                Code administrateur : <span class="color-emotivi-blue" @click="copieClipboard(row_en.en_admincode)">{{ row_en.en_admincode }}</span>
                <hr class="hr-mini" />
                <!-- Status : <span class="color-emotivi-blue" @click="copieClipboard(row_en.en_admincode)">{{ row_en.en_active }}</span
                ><br /> -->
                Chambre : {{ row_en.en_room ? row_en.en_room : "" }}<br />
                Statut box :
                <span class="color-emotivi-blue" @click="copieClipboard(row_en.en_admincode)" :style="getColorStatus(row_en.en_state_box)">{{
                  $Utils.getTextFromState($store.state.items_en_state_box, row_en.en_state_box)
                }}</span
                ><br />
                Statut frais de dossier/caution:
                <span class="color-emotivi-blue" @click="copieClipboard(row_en.en_admincode)" :style="getColorStatus(row_en.en_state_caution)">{{
                  $Utils.getTextFromState($store.state.items_en_state_caution, row_en.en_state_caution)
                }}</span
                ><br />
                Statut abonnement:
                <span class="color-emotivi-blue" @click="copieClipboard(row_en.en_admincode)" :style="getColorStatus(row_en.en_state_subscription)">{{
                  $Utils.getTextFromState($store.state.items_en_state_subscription, row_en.en_state_subscription)
                }}</span
                ><br />
                Statut colis:
                <span class="color-emotivi-blue" @click="copieClipboard(row_en.en_admincode)" :style="getColorStatus(row_en.en_state_parcel)">{{
                  $Utils.getTextFromState($store.state.items_en_state_parcel, row_en.en_state_parcel)
                }}</span
                ><br />
                Statut support:
                <span class="color-emotivi-blue" @click="copieClipboard(row_en.en_admincode)" :style="getColorStatus(row_en.en_state_support)">{{
                  $Utils.getTextFromState($store.state.items_en_state_support, row_en.en_state_support)
                }}</span
                ><br />
                <span v-if="row_en.en_box_mac_address_wifi"
                  ><hr class="hr-mini" />
                  Adresse Mac WIFI : {{ row_en.en_box_mac_address_wifi }}</span
                >
                <span v-if="row_en.en_box_mac_address_ethernet"
                  ><hr class="hr-mini" />
                  Adresse Mac Ethernet: {{ row_en.en_box_mac_address_ethernet }}</span
                >
                <br />
                <div v-if="row_en.de_id">
                  <button class="btn btn-danger mb-2" style="width: 180px" @click="resetTv">Réinitialiser la TV</button> <br />
                  <button class="btn btn-danger mb-2" style="width: 180px" @click="deleteTv">Supprimer la TV</button>
                </div>
              </div>
              <!-- en_comment n'existe pas -->
              <!-- <div class="bis uppercase mt-3">Commentaire</div>
                  <div>{{ row_en.en_comment ? row_en.en_comment : "" }}</div> -->
            </div>
            <!-- PARCELS CARD -->
            <div v-if="$store.getters.checkUserAccess($router, '/parcels/:en_id')" class="card">
              <div class="d-flex flex-row flex-wrap justify-content-between">
                <h6>
                  <span v-show="$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'" class="number-title">4</span
                  >Envois colis
                </h6>
                <button class="btn btn-primary" @click="newParcel">Ajouter</button>
              </div>
              <div class="d-flex flex-row flex-wrap justify-content-between mt-3" v-for="parcel in parcels" :key="parcel.pa_id">
                <div>
                  <div>
                    Type de connexion :
                    <span
                      class="color-emotivi-blue"
                      :class="getBold(row_co.co_box_connection_type)"
                      :style="{ color: getColor(row_co.co_box_connection_type) }"
                      >{{ row_co.co_box_connection_type }}</span
                    >
                  </div>

                  <div>
                    Société : <span class="color-emotivi-blue">{{ parcel.pa_company }}</span>
                  </div>
                  <div>
                    N° suivi : <span class="color-emotivi-blue">{{ parcel.pa_trackingcode }}</span>
                  </div>
                  <div v-if="parcel.pa_sent_date && parcel.pa_sent_date != '0000-00-00 00:00:00'">
                    Date d'envoi :
                    <span class="color-emotivi-blue">{{ $moment(parcel.pa_sent_date).format("DD/MM/YYYY HH[H]mm") }}</span>
                  </div>
                  <div>
                    User :
                    <span class="color-emotivi-blue">{{ parcel.us_id ? $options.filters.formatContactNameSimple(parcel.us_id, "us_") : "" }}</span>
                  </div>
                  <div>
                    Etat :<span class="color-emotivi-blue"> {{ $Utils.getTextFromState($store.state.items_en_state_parcel, parcel.pa_state) }}</span>
                  </div>
                  <div>
                    Date : <span class="color-emotivi-blue">{{ $moment(parcel.updatedAt).format("DD/MM/YYYY HH[H]mm") }}</span>
                  </div>
                  <div>
                    Tarif :<span class="color-emotivi-blue"> {{ parcel.pa_price }}</span>
                  </div>
                </div>
                <div class="d-flex flex-column align-items-end">
                  <button class="btn btn-primary btn-sm w-100" @click="editParcel(parcel)">Modifier</button>
                  <button
                    class="btn btn-warning my-1 btn-sm w-100"
                    @click="mailParcel(parcel)"
                    :disabled="parcel.pa_state !== 'SENT' && parcel.pa_state !== 'PREPARING'"
                  >
                    Envo. mail
                  </button>
                  <a class="w-100" :href="'https://www.laposte.fr/outils/suivre-vos-envois?code=' + parcel.pa_trackingcode" target="_blank"
                    ><button class="btn btn-warning btn-sm w-100">Suivre</button></a
                  >
                </div>
              </div>
            </div>
            <!-- SUMMARY CARD -->
            <div v-show="$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'" class="card">
              <h6>
                <span v-show="$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'" class="number-title">5</span
                >Résumé
              </h6>
              <div class="row mt-2">
                <div class="col-md-12">
                  <div>
                    Inscription le :
                    <span class="color-emotivi-blue ml-2">
                      {{
                        row_en.infos.actionsFirstTime && row_en.infos.actionsFirstTime.inscription
                          ? $moment(row_en.infos.actionsFirstTime.inscription).format("DD/MM/YYYY")
                          : ""
                      }}
                    </span>
                  </div>
                  <div>
                    Première install. le :
                    <span class="color-emotivi-blue ml-2">
                      {{
                        row_en.infos.actionsFirstTime && row_en.infos.actionsFirstTime.install
                          ? $moment(row_en.infos.actionsFirstTime.install).format("DD/MM/YYYY")
                          : ""
                      }}
                    </span>
                  </div>
                  <div>
                    Envoi colis le :
                    <span class="color-emotivi-blue ml-2">
                      {{
                        row_en.infos.actionsFirstTime && row_en.infos.actionsFirstTime.parcel_sended
                          ? $moment(row_en.infos.actionsFirstTime.parcel_sended).format("DD/MM/YYYY")
                          : ""
                      }}
                    </span>
                  </div>
                  <div>
                    Dernière connexion le :
                    <span class="color-emotivi-blue ml-2">
                      {{
                        row_en.infos.actionsFirstTime && row_en.infos.actionsFirstTime.connection
                          ? $moment(row_en.infos.actionsFirstTime.connection).format("DD/MM/YYYY")
                          : ""
                      }}
                    </span>
                  </div>
                  <div>
                    Réception colis le :
                    <span class="color-emotivi-blue ml-2">
                      {{
                        row_en.infos.actionsFirstTime && row_en.infos.actionsFirstTime.parcel_received
                          ? $moment(row_en.infos.actionsFirstTime.parcel_received).format("DD/MM/YYYY")
                          : ""
                      }}
                    </span>
                  </div>
                  <div>
                    Premier appel vidéo le :
                    <span class="color-emotivi-blue ml-2">
                      {{
                        row_en.infos.actionsFirstTime && row_en.infos.actionsFirstTime.videocall_firstSuccess
                          ? $moment(row_en.infos.actionsFirstTime.videocall_firstSuccess).format("DD/MM/YYYY")
                          : ""
                      }}
                    </span>
                  </div>
                  <div>
                    Premier appel audio le :
                    <span class="color-emotivi-blue ml-2">
                      {{
                        row_en.infos.actionsFirstTime && row_en.infos.actionsFirstTime.audiocall_firstSuccess
                          ? $moment(row_en.infos.actionsFirstTime.audiocall_firstSuccess).format("DD/MM/YYYY")
                          : ""
                      }}
                    </span>
                  </div>
                  <div>
                    Dernier appel le :
                    <span class="color-emotivi-blue ml-2">
                      {{
                        row_en.infos.actionsFirstTime && row_en.infos.actionsFirstTime.lastSuccessCall
                          ? $moment(row_en.infos.actionsFirstTime.lastSuccessCall).format("DD/MM/YYYY")
                          : ""
                      }}
                    </span>
                  </div>
                  <div>
                    VIDÉO/OK/VOIX/OK/MESSAGE
                    <span class="color-emotivi-blue ml-2">
                      <span v-for="(number, index) in row_en.infos.nbCalls" :key="index"
                        >{{ number }} <span v-if="index < row_en.infos.nbCalls.length - 1">/ </span></span
                      >
                    </span>
                  </div>
                  <div
                    class="d-flex flex-column"
                    v-if="
                      row_en.en_suspended_service_dates &&
                      Array.isArray(row_en.en_suspended_service_dates) &&
                      row_en.en_suspended_service_dates.length
                    "
                  >
                    Le service a été suspendu le(s) :
                    <div class="alert alert-warning d-flex flex-column" v-for="(date, index) in row_en.en_suspended_service_dates" :key="index">
                      <span v-show="$moment(date.start).isValid()">
                        - Début : <strong>{{ $moment(date.start).format("DD/MM/YYYY HH:mm:ss") }} </strong></span
                      >
                      <span v-show="$moment(date.end).isValid()">
                        - Fin :
                        <strong>{{ $moment(date.end).format("DD/MM/YYYY HH:mm:ss") }}</strong>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="filterInfoTech">
        <div class="row">
          <div class="col-md-9">
            <h3 class="">Informations techniques</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-9">
            <!-- TECHNICAL INFORMATION CARD -->
            <div class="card">
              <div class="d-flex flex-row justify-content-between">
                <h6>
                  <span v-show="$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'" class="number-title">1</span
                  >Informations techniques
                </h6>
              </div>
              <div class="row">
                <div class="col-md-6 col-lg-3 d-flex flex-column">
                  <button class="btn btn-primary mt-2" :disabled="!row_en.bo_id" @click="editBoxe">Options</button>
                  <button class="btn btn-primary mt-2" :disabled="!row_en.bo_id" @click="editBoxe">Modifier plage horaire</button>
                  <button
                    class="btn btn-primary mt-2"
                    :disabled="!row_en.bo_id"
                    @click="displayBoxLogs"
                    v-if="$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'TECHNICIAN'"
                  >
                    Remonter les logs
                  </button>
                </div>
                <div class="col-md-6 col-lg-3 d-flex flex-column">
                  <button class="btn btn-primary mt-2" :disabled="!row_en.bo_id" @click="showConfigPage">Ouvrir config</button>
                  <button class="btn btn-primary mt-2" :disabled="!row_en.bo_id" @click="showAnimation">Afficher l'animation</button>
                  <button v-if="$Utils.isServiceProvider()" class="btn btn-primary mt-2" @click="reinitBoxe()">Réinitialiser la boxe</button>
                </div>
                <div class="col-md-6 col-lg-3 d-flex flex-column">
                  <button class="btn btn-primary mt-2" :disabled="!row_en.bo_id" @click="diagnostikReseau">Diagnostic réseau</button>
                  <button v-if="$store.state.user.us_type === 'LATEAM'" :disabled="!row_en.bo_id" class="btn btn-primary mt-2" @click="startSSH">
                    SSH port {{ row_bo.bo_ssh_port }}
                  </button>
                </div>
                <div class="col-md-6 col-lg-3 d-flex flex-column">
                  <button class="btn btn-danger mt-2" :disabled="!row_en.bo_id" @click="rebootBox">Redémarrer la box</button>
                  <button class="btn btn-danger mt-2" :disabled="!row_en.bo_id" @click="restartBox">Redémarrer l'app</button>
                  <div class="">
                    <box-versions-select
                      v-if="($store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'TECHNICIAN') && row_bo.bo_id"
                      :row_bo="row_bo"
                    >
                    </box-versions-select>
                    <button
                      v-if="$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'TECHNICIAN'"
                      class="btn btn-danger mt-2 w-100"
                      :disabled="!row_en.bo_id"
                      @click="updateBox"
                    >
                      Mettre à jour
                    </button>
                  </div>
                </div>
              </div>

              <div class="row my-3">
                <div class="col-md-6">
                  <div class="bis uppercase mr-2" v-if="row_bo.bo_callhours">Plages horaire :</div>
                  <div class="d-flex flex-row flex-wrap mb-2">
                    <div v-for="(plage, index) in row_bo.bo_callhours" :key="index">
                      <div v-if="plage.start">
                        {{ plage.start.split(":").join("H") }}-{{ plage.end.split(":").join("H")
                        }}<span class="mx-1" v-if="index < row_bo.bo_callhours.length - 1"> / </span>
                      </div>
                    </div>
                  </div>
                  <!-- <table style="width: 100%">
                    <tr>
                      <td>bo_last_requested</td>
                      <td class="color-emotivi-blue text-right">{{ row_bo.bo_last_requested | formatDateTime }}</td>
                    </tr>
                  </table> -->
                </div>
              </div>

              <div class="row">
                <div class="col">
                  Type de connexion :
                  <select
                    id="co_box_connection_type"
                    name="co_box_connection_type"
                    v-model="row_co.co_box_connection_type"
                    v-on:change="onChangeConnectionType($event)"
                    class="color-emotivi-blue mb-4"
                    style="border: solid; border-radius: 0; text-align: center"
                  >
                    <option value="ETHERNET">Câble ethernet</option>
                    <option value="WIFI">WiFi</option>
                    <option value="CLE4G_PERSO">Clé 4G personnelle</option>
                    <option value="CLE4G_EMOTIVI">Clé 4G Emotivi</option>
                    <option value="ROUTEUR4G_EMOTIVI">Routeur 4G Emotivi</option>
                    <option value="ROUTEUR4G_PERSO">Routeur 4G personnel</option>
                  </select>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <button class="btn btn-warning" :disabled="!row_en.bo_id" @click="showMainDetailsBox = !showMainDetailsBox">
                    <span v-if="!showMainDetailsBox">Afficher</span><span v-if="showMainDetailsBox">Masquer</span> les principales informations de la
                    box
                  </button>
                </div>
              </div>
              <div class="row mt-4" v-if="showMainDetailsBox">
                <div class="col-md-12 d-flex flex-column" v-if="row_bo">
                  <div v-for="(tab, indexTab) in tabsInfos" :key="indexTab" class="mb-3">
                    <div class="bis uppercase">{{ tab.value }}</div>
                    <div v-for="info in maininfos(tab.key)" :key="info.key" class="row tr-boxedetail">
                      <div class="col-md-6" width="200px">{{ info.key }}</div>
                      <div class="col-md-6 color-emotivi-blue">
                        <pre><code>{{ info.val }}</code></pre>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <button
                      class="btn btn-warning"
                      :disabled="!row_en.bo_id"
                      @click="showDetailsBox = !showDetailsBox"
                      v-if="$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'TECHNICIAN'"
                    >
                      <span v-if="!showDetailsBox">Afficher</span><span v-if="showDetailsBox">Masquer</span> les détails avancés de la box
                    </button>
                  </div>
                </div>
              </div>
              <div class="row mt-4" v-if="showDetailsBox">
                <div class="col-md-12 d-flex flex-column" v-if="row_bo">
                  <div v-for="(tab, indexTab) in tabsInfos" :key="indexTab" class="mb-3">
                    <div class="bis uppercase">{{ tab.value }}</div>
                    <div v-for="info in allinfos(tab.key)" :key="info.key" class="row tr-boxedetail">
                      <div class="col-md-3" width="200px">{{ info.key }}</div>
                      <div class="col-md-9 color-emotivi-blue">
                        <pre><code>{{ info.val }}</code></pre>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- MESSAGES CARD -->
            <div class="card">
              <div class="uppercase bis mb-3">
                <span v-show="$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'" class="number-title">4</span
                >Messages
              </div>
              <div class="row">
                <div class="col-md-12">
                  <button class="btn btn-warning mt-1 mr-2" :disabled="!row_en.bo_id" @click="showMessages = !showMessages">
                    <span v-if="!showMessages">Afficher</span><span v-if="showMessages">Masquer</span> les messages
                  </button>
                  <button class="btn btn-primary mt-1 mr-2" :disabled="!row_en.bo_id" @click="addMessage = !addMessage">Envoyer un message</button>
                  <button class="btn btn-primary mt-1 mr-2" :disabled="!row_en.bo_id" @click="scheduleMessage = !scheduleMessage">
                    Planifier un message
                  </button>
                  <button class="btn btn-primary mt-1 mr-2" :disabled="!row_en.bo_id" @click="videoCall">Démarrer un appel vidéo</button>
                  <button class="btn btn-primary mt-1 mr-2" :disabled="!row_en.bo_id" @click="audioCall">Démarrer un appel audio</button>
                  <button class="btn btn-danger mt-1 mr-2" :disabled="!row_en.bo_id" @click="deleteAllMessages">Effacer les messages</button>
                </div>
              </div>
              <div class="row d-flex flex-row align-items-center" v-if="addMessage">
                <div class="col-md-12">
                  <div class="d-flex flex-column">
                    <m-form-checkbox
                      label="Message de type ADMIN"
                      :name="$Utils.randomstring('isAdmin')"
                      v-model="isAdmin"
                      class="mt-2"
                    ></m-form-checkbox>
                    <m-form-checkbox
                      v-if="isAdmin"
                      label="Confirmation de lecture"
                      :name="$Utils.randomstring('acknowledgment')"
                      v-model="acknowledgment"
                    ></m-form-checkbox>
                    <m-form-minutes
                      v-if="isAdmin"
                      class="mx-1"
                      style="overflow-wrap: normal"
                      label="Durée d'affichage (minutes:secondes)"
                      :name="$Utils.randomstring('duration')"
                      v-model="duration"
                    ></m-form-minutes>
                  </div>

                  <m-form-text label="Message" type="textarea" v-model="newMessage" name="message" class=""></m-form-text>
                  <!-- <m-form-file class="mt-4" label="Choisissez un avatar" ref="inputImageAvatar" v-model="fileSelected" @inputfile="fileJusteSelected">
                  </m-form-file> -->
                  <div v-show="!photoJoined">
                    <label class="fileContainer d-flex justify-content-center" for="upload">
                      <div class="align-self-center">Choisir un fichier</div>
                    </label>
                    <input
                      type="file"
                      class="form-control inputImage"
                      @change="handleFiles()"
                      id="upload"
                      name="file"
                      ref="myFiles"
                      style="display: none"
                    />
                  </div>
                  <div v-show="photoJoined">
                    <label id="container-media" for="upload" ref="previewContainer" style="width: 100%">
                      <div id="container-media-content" ref="preview"></div>
                    </label>
                    <button class="btn btn-rotate mr-2 btn-img rotate-left" v-on:click.prevent="rotateFiles($event, 'left')">
                      <icon name="undo-alt"></icon>
                    </button>
                    <button class="btn btn-rotate mr-2 btn-img rotate-right" v-on:click.prevent="rotateFiles($event, 'right')">
                      <icon name="redo-alt"></icon>
                    </button>
                    <button class="btn btn-danger mr-2 btn-img trash" v-on:click.prevent="deleteFiles($event)">
                      <icon name="trash-alt"></icon>
                    </button>
                  </div>
                  <button class="btn btn-primary mt-2" @click="sendMessage">Envoyer</button>
                </div>
              </div>
              <div class="row" v-if="showMessages">
                <div class="col-md-12">
                  <m-list-simple ref="commandelist" :dynamic="true" :items="messages" item-value="me_id" :total="messages_length">
                    <template v-slot:ths="{}">
                      <th width="150px">
                        <span class="pointer">Date</span>
                      </th>
                      <th width="150px">
                        <span class="pointer">Auteur</span>
                      </th>
                      <th width="100px">
                        <span class="pointer">Avatar</span>
                      </th>
                      <th width="70px">
                        <span class="pointer">Type</span>
                      </th>
                      <th>
                        <span class="pointer">Message</span>
                      </th>
                      <th width="70px">
                        <span class="pointer">Visible</span>
                      </th>
                    </template>
                    <template v-slot:tds="{ item }">
                      <td>{{ $moment(item.me_date).format("DD/MM/YYYY") }}</td>
                      <td>{{ item.me_pseudo }}</td>
                      <td>
                        <div class="container-avatar" :style="'background-image:url(' + $config.server_url + item.me_avatar + ')'"></div>
                        <!-- <img class="img-fluid rounded-circle" slot="image" :src="$config.server_url + item.me_avatar" /> -->
                      </td>
                      <td>{{ item.me_type }}</td>
                      <td>{{ item.me_text }}</td>
                      <td>{{ item.me_invisible ? "Non" : "Oui" }}</td>
                    </template>
                  </m-list-simple>
                </div>
              </div>
              <modal-schedule-message v-model="scheduleMessage" :row_en="row_en"></modal-schedule-message>
            </div>
            <!-- ACTIONS CARD -->
            <div class="card">
              <div class="uppercase bis mb-3" v-if="$store.state.user.us_type === 'LATEAM'">
                <span v-show="$store.state.user.us_type === 'LATEAM'" class="number-title">5</span>Les actions
              </div>
              <div class="uppercase bis mb-3" v-if="$store.state.user.us_type !== 'LATEAM'">
                <span v-show="$store.state.user.us_type === 'LATEAM'" class="number-title">5</span>Commentaire
              </div>
              <div class="row d-flex flex-row align-items-end mb-2">
                <div class="col-md-6 col-lg-7 col-xl-8 d-flex flex-column">
                  <div v-if="dialogAlertAction" class="col">
                    <p class="alert alert-danger">{{ dialogAlertAction }}</p>
                  </div>
                  <m-form-text
                    class="ml-3"
                    type="textarea"
                    :rows="1"
                    :label="$store.state.user.us_type === 'LATEAM' ? 'Ajouter une action' : 'Commentaire'"
                    label-position="top"
                    v-model="newCommentAction"
                    name="newCommentAction"
                  ></m-form-text>
                </div>
                <div class="col-md-6 col-lg-5 col-xl-4">
                  <button class="btn btn-primary ml-2 mb-2" @click="addCommentAction">
                    {{ $store.state.user.us_type === "LATEAM" ? "Ajouter" : "Ajouter un commentaire" }}
                  </button>
                </div>
              </div>
              <div class="row align-items-end mb-2">
                <div class="col-md-5 col-lg-4">
                  <button class="btn btn-warning ml-3" @click="loadActions('showList')">
                    <span v-if="!showActions">Afficher</span><span v-if="showActions">Masquer</span> les actions
                  </button>
                </div>
                <!-- LIST ACTIONS -->
                <div class="col-md-4 col-lg-4" v-if="$store.state.user.us_type === 'LATEAM'">
                  <m-form-select
                    label="Type d'action"
                    v-model="selectedActionType"
                    :name="$Utils.randomstring('ac_type')"
                    :items="listActions"
                  ></m-form-select>
                </div>
                <div class="col-md-3 col-lg-4 mb-n2" v-if="$store.state.user.us_type === 'LATEAM'">
                  <m-form-date
                    class="mx-1"
                    label="Date de l'action"
                    :name="$Utils.randomstring('ac_date')"
                    formatInput="DD/MM/YYYY HH:mm:ss"
                    formatValue="YYYY-MM-DD HH:mm:ss"
                    v-model="actionDate"
                  ></m-form-date>
                </div>
              </div>
              <div v-if="showActions">
                <m-list-simple ref="commandelist" :limit="300" :dynamic="true" :items="actions" item-value="ac_id" :total="action_length">
                  <template v-slot:ths="{}">
                    <th width="160px">
                      <span class="pointer">Date</span>
                    </th>
                    <th width="220px">
                      <span class="pointer">Type</span>
                    </th>
                    <th width="220px">
                      <span class="pointer">Contact</span>
                    </th>
                    <th width="220px">
                      <span class="pointer">Utilisateur final</span>
                    </th>
                    <th width="220px">
                      <span class="pointer">User</span>
                    </th>
                    <th>
                      <span class="pointer">Infos</span>
                    </th>
                  </template>
                  <template v-slot:tds="{ item }">
                    <td>{{ item.createdAt | formatDateTime }}</td>
                    <td>{{ item.ac_type }}</td>
                    <td>{{ item.co_id | formatContactNameSimple }}</td>
                    <td>{{ item.en_id | formatEndUserName }}</td>
                    <td>{{ item.us_id | formatUserName }}</td>
                    <td>
                      <span v-if="item.ac_comment">{{ item.ac_comment }}</span>
                      <span v-if="item.ac_elapsedtime > 0">Durée : {{ Math.floor((item.ac_elapsedtime / 60) * 100) / 100 }}mn</span>
                    </td>
                  </template>
                </m-list-simple>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <!-- END USERS CARD -->
            <div class="card">
              <div class="d-flex flex-row flex-wrap justify-content-between">
                <h6>
                  <span v-show="$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'" class="number-title">2</span
                  >Utilisateur final
                </h6>
                <div>
                  <button class="btn btn-primary mb-2" @click="editEnduser">Modifier</button>
                </div>
              </div>

              <div>
                <div v-if="row_en.en_linkello">
                  <span class="linkello">Associé à Linkello</span>
                </div>
                Nom complet : {{ row_en.en_fullname }}
                <hr class="hr-mini" />
                <div class="float-right">
                  <button class="btn btn-warning btn-sm mt-2" @click="sendInvitationEmail">Renvoyer</button>
                </div>
                Ident. webapp :
                <span class="color-emotivi-blue" @click="copieClipboard(row_en.en_webapp_login)">{{ row_en.en_webapp_login }}</span
                ><br />
                Pass webapp :<span class="color-emotivi-blue" @click="copieClipboard(row_en.en_webapp_password)">
                  {{ row_en.en_webapp_password }}</span
                >
                <hr class="hr-mini" />
                Code administrateur : <span class="color-emotivi-blue" @click="copieClipboard(row_en.en_admincode)">{{ row_en.en_admincode }}</span>
                <hr class="hr-mini" />
                <!-- Status : <span class="color-emotivi-blue" @click="copieClipboard(row_en.en_admincode)">{{ row_en.en_active }}</span
                ><br /> -->
                Chambre : {{ row_en.en_room ? row_en.en_room : "" }} <br />
                Statut box :
                <span class="color-emotivi-blue" @click="copieClipboard(row_en.en_admincode)" :style="getColorStatus(row_en.en_state_box)">{{
                  $Utils.getTextFromState($store.state.items_en_state_box, row_en.en_state_box)
                }}</span
                ><br />
                Statut frais de dossier/caution:
                <span class="color-emotivi-blue" @click="copieClipboard(row_en.en_admincode)" :style="getColorStatus(row_en.en_state_caution)">{{
                  $Utils.getTextFromState($store.state.items_en_state_caution, row_en.en_state_caution)
                }}</span
                ><br />
                Statut abonnement:

                <span class="color-emotivi-blue" @click="copieClipboard(row_en.en_admincode)" :style="getColorStatus(row_en.en_state_subscription)">{{
                  $Utils.getTextFromState($store.state.items_en_state_subscription, row_en.en_state_subscription)
                }}</span
                ><br />
                Statut colis:
                <span class="color-emotivi-blue" @click="copieClipboard(row_en.en_admincode)" :style="getColorStatus(row_en.en_state_parcel)">{{
                  $Utils.getTextFromState($store.state.items_en_state_parcel, row_en.en_state_parcel)
                }}</span
                ><br />
                Statut support:
                <span class="color-emotivi-blue" @click="copieClipboard(row_en.en_admincode)" :style="getColorStatus(row_en.en_state_support)">{{
                  $Utils.getTextFromState($store.state.items_en_state_support, row_en.en_state_support)
                }}</span
                ><br />
                <hr class="hr-mini" />
                Adresse Mac Box : {{ row_en.bo_id && typeof row_en.bo_id === "object" ? row_en.bo_id.bo_mac_address : "" }}<br />
                <br />
              </div>
              <!-- en_comment n'existe pas -->
              <!-- <div class="bis uppercase mt-3">Commentaire</div>
                  <div>{{ row_en.en_comment ? row_en.en_comment : "" }}</div> -->
            </div>
            <!-- SUMMARY CARD -->
            <div class="card">
              <h6>
                <span v-show="$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'" class="number-title">3</span
                >Résumé
              </h6>
              <div class="row mt-2">
                <div class="col-md-12">
                  <div>
                    Inscription le :
                    <span class="color-emotivi-blue ml-2">
                      {{
                        row_en.infos.actionsFirstTime && row_en.infos.actionsFirstTime.inscription
                          ? $moment(row_en.infos.actionsFirstTime.inscription).format("DD/MM/YYYY")
                          : ""
                      }}
                    </span>
                  </div>
                  <div>
                    Première install. le :
                    <span class="color-emotivi-blue ml-2">
                      {{
                        row_en.infos.actionsFirstTime && row_en.infos.actionsFirstTime.install
                          ? $moment(row_en.infos.actionsFirstTime.install).format("DD/MM/YYYY")
                          : ""
                      }}
                    </span>
                  </div>
                  <div>
                    Envoi colis le :
                    <span class="color-emotivi-blue ml-2">
                      {{
                        row_en.infos.actionsFirstTime && row_en.infos.actionsFirstTime.parcel_sended
                          ? $moment(row_en.infos.actionsFirstTime.parcel_sended).format("DD/MM/YYYY")
                          : ""
                      }}
                    </span>
                  </div>
                  <div>
                    Dernière connexion le :
                    <span class="color-emotivi-blue ml-2">
                      {{
                        row_en.infos.actionsFirstTime && row_en.infos.actionsFirstTime.connection
                          ? $moment(row_en.infos.actionsFirstTime.connection).format("DD/MM/YYYY")
                          : ""
                      }}
                    </span>
                  </div>
                  <div>
                    Réception colis le :
                    <span class="color-emotivi-blue ml-2">
                      {{
                        row_en.infos.actionsFirstTime && row_en.infos.actionsFirstTime.parcel_received
                          ? $moment(row_en.infos.actionsFirstTime.parcel_received).format("DD/MM/YYYY")
                          : ""
                      }}
                    </span>
                  </div>
                  <div>
                    Premier appel vidéo le :
                    <span class="color-emotivi-blue ml-2">
                      {{
                        row_en.infos.actionsFirstTime && row_en.infos.actionsFirstTime.videocall_firstSuccess
                          ? $moment(row_en.infos.actionsFirstTime.videocall_firstSuccess).format("DD/MM/YYYY")
                          : ""
                      }}
                    </span>
                  </div>
                  <div>
                    Premier appel audio le :
                    <span class="color-emotivi-blue ml-2">
                      {{
                        row_en.infos.actionsFirstTime && row_en.infos.actionsFirstTime.audiocall_firstSuccess
                          ? $moment(row_en.infos.actionsFirstTime.audiocall_firstSuccess).format("DD/MM/YYYY")
                          : ""
                      }}
                    </span>
                  </div>
                  <div>
                    Dernier appel le :
                    <span class="color-emotivi-blue ml-2">
                      {{
                        row_en.infos.actionsFirstTime && row_en.infos.actionsFirstTime.lastSuccessCall
                          ? $moment(row_en.infos.actionsFirstTime.lastSuccessCall).format("DD/MM/YYYY")
                          : ""
                      }}
                    </span>
                  </div>
                  <div>
                    VIDÉO/OK/VOIX/OK/MESSAGE
                    <span class="color-emotivi-blue ml-2">
                      <span v-for="(number, index) in row_en.infos.nbCalls" :key="index"
                        >{{ number }} <span v-if="index < row_en.infos.nbCalls.length - 1">/ </span></span
                      >
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row d-flex align-items-end mb-2">
        <div class="col-md-9">
          <h3 class="">Actions</h3>
        </div>
      </div> -->
    </div>
    <m-message-dialog v-model="dialogMessage" :title="dialogMessageTitle" :text="dialogMessageTxt"></m-message-dialog>
    <m-select-dialog
      v-model="dialogConfirmCancelSubscriptionRib"
      text="Il s'agit d'un abonnement SEPA, souhaitez vous définir l'état comme :"
      title="Confirmation"
      :selectValues="[
        { text: `En attente d'annulation`, value: 1 },
        { text: 'Inactif (abonnement déjà arrêté auprés de la banque)', value: 0 }
      ]"
      width="750px"
      height="350px"
      @canceled="dialogConfirmCancelSubscriptionRib = false"
      @confirmed="
        $event => {
          this.dialogConfirmCancelSubscriptionRib = false;
          this.cancelSubscriptionData.su_state = $event;
          this.dialogConfirmSendEmailCancelSubscription = true;
        }
      "
    ></m-select-dialog>
    <m-confirm-dialog
      v-model="dialogConfirmCancelSubscription"
      text="Souhaitez-vous résilier cet abonnement ?"
      title="Confirmation"
      width="500px"
      @canceled="dialogConfirmCancelSubscription = false"
      @confirmed="
        () => {
          this.dialogConfirmCancelSubscription = false;
          this.dialogConfirmSendEmailCancelSubscription = true;
        }
      "
    ></m-confirm-dialog>
    <m-confirm-dialog
      v-model="dialogConfirmCancelCharge"
      text="Souhaitez-vous rembourser ce paiement ?"
      title="Confirmation"
      width="500px"
      @canceled="dialogConfirmCancelCharge = false"
      @confirmed="cancelChargeConfirmed()"
    ></m-confirm-dialog>
    <m-confirm-dialog
      ref="dialogConfirmStartSubscription"
      v-model="dialogConfirmStartSubscription"
      :text="dialogConfirmStartSubscriptionText"
      title="Confirmation"
      width="600px"
      @canceled="dialogConfirmStartSubscription = false"
      @confirmed="startSubscription()"
    ></m-confirm-dialog>
    <m-confirm-dialog
      v-model="dialogConfirmStartLoan"
      text="Souhaitez-vous prêter le kit ?"
      title="Confirmation"
      width="500px"
      @canceled="dialogConfirmStartLoan = false"
      @confirmed="startLoan()"
    ></m-confirm-dialog>
    <m-confirm-dialog
      v-model="dialogConfirmStopLoan"
      text="Le kit a t'il été restitué ?"
      title="Confirmation"
      width="500px"
      @canceled="dialogConfirmStopLoan = false"
      @confirmed="stopLoan()"
    ></m-confirm-dialog>
    <m-confirm-dialog
      v-model="dialogConfirmWaitingRefund"
      text="Souhaitez-vous déclarer le contact en attente de remboursement ? Vous avez également la possibilité de rembourser directement le contact en cliquant sur la poubelle de la ligne 'Paiements'"
      title="Confirmation"
      width="500px"
      @canceled="dialogConfirmWaitingRefund = false"
      @confirmed="startWaitingRefund()"
    ></m-confirm-dialog>
    <m-confirm-dialog
      v-model="dialogConfirmSendEmailCancelSubscription"
      text="Souhaitez vous envoyer un email de résiliation au contact."
      title="Annuler l'abonnement"
      width="500px"
      cancelText="Non"
      successText="Oui"
      @canceled="
        () => {
          this.cancelSubscriptionData.en_send_cancel_sub_email = 0;
          this.dialogConfirmSendEmailCancelSubscription = false;
          /** if it's a RIB (no gocardless) cancellation and this.cancelSubscriptionData.su_state === 1 (waiting for cancelation) then do not display next alert that ask to user to cancel subscription now or at next programmed charge*/
          if (this.cancelSubscriptionData.hasOwnProperty('su_state') && this.cancelSubscriptionData.su_state === 1) this.cancelSubscription('');
          else this.dialogConfirmCancelSubscriptionNow = true;
        }
      "
      @confirmed="
        () => {
          this.cancelSubscriptionData.en_send_cancel_sub_email = 1;
          this.dialogConfirmSendEmailCancelSubscription = false;
          /** if it's a RIB (no gocardless) cancellation and this.cancelSubscriptionData.su_state === 1 (waiting for cancelation) then do not display next alert that ask to user to cancel subscription now or at next programmed charge*/
          if (this.cancelSubscriptionData.hasOwnProperty('su_state') && this.cancelSubscriptionData.su_state === 1) this.cancelSubscription('');
          this.dialogConfirmCancelSubscriptionNow = true;
        }
      "
    ></m-confirm-dialog>
    <m-confirm-dialog
      v-model="dialogConfirmCancelSubscriptionNow"
      text="Souhaitez vous annuler l'abonnement immédiatement ? Si oui, la date de fin sera enregistrée à ce jour, sinon à la fin de la période de facturation."
      title="Annuler l'abonnement"
      width="500px"
      cancelText="Non"
      successText="Oui"
      @canceled="cancelSubscription('')"
      @confirmed="cancelSubscription('?now=ok')"
    ></m-confirm-dialog>
    <parcel-win-edit :en_id="row_en.en_id" :pa_id="parcelSelected.pa_id" v-model="dialogParcel" @parcelSaved="parcelSaved"></parcel-win-edit>
    <boxe-win-edit :row_en="row_en" :row_bo="row_bo" v-if="dialogBoxe" v-model="dialogBoxe" @saved="boxeSaved" @canceled="boxeCancel"></boxe-win-edit>
    <boxe-win-display-logs :row_en="row_en" v-model="dialogBoxeLog" @canceled="boxeCancel"></boxe-win-display-logs>
    <contact-win-edit :co_id="row_co.co_id" v-model="dialogContact" @saved="reloadEnduser" @canceled="cancelWin"></contact-win-edit>
    <enduser-win-edit
      :en_id="row_en.en_id"
      :bo_id="boxId"
      v-model="dialogEnduser"
      @winEditSaved="reloadEnduser"
      @winEditCanceled="cancelWin"
      @winEditDeleted="deletedEnduser"
    ></enduser-win-edit>
    <attestation-fiscale
      v-if="$store.getters.checkUserAccess($router, '/invoices')"
      v-model="displayFormAttestationFiscale"
      @winRequestAttestationCanceled="cancelWinRequestAttestation"
      :row_fisc="attestations[0]"
      :row_en="row_en"
    ></attestation-fiscale>
    <payment-details
      v-if="selectedPayment"
      :payment="selectedPayment"
      v-model="displayPaymentDetails"
      @winClose="closeWin('displayPaymentDetails')"
    ></payment-details>
    <m-message-dialog v-model="mailParcelSend" title="Envoyé" text="Email Colissimo envoyé" @close="mailParcelSend = false"></m-message-dialog>
    <m-message-dialog
      v-model="deletedAllMessages"
      title="Messages effacés"
      text="Tous les messages ont été effacés"
      @close="deletedAllMessages = false"
    ></m-message-dialog>
    <video-call
      v-if="displayVideoCall && row_bo"
      :row_bo="row_bo"
      :en_id="row_en.en_id"
      :type="callType"
      v-model="dialogVideoCall"
      @hangup="videoCallHangup"
    ></video-call>
    <!-- <video-call  :row_bo="row_bo" :en_id="row_en.en_id" v-model="dialogVideoCall" @hangup="videoCallHangup"></video-call> -->
    <cancel-charge
      @amountCancelChargeChanged="cancelCharge"
      v-model="dialogCancelCharge"
      :maxvalue="(this.valueChargeAmount - this.valueChargeRefunded) / 100"
    ></cancel-charge>
    <div style="display: none">
      <canvas ref="canvas" width="1024" height="768"></canvas>
    </div>
    <popover ref="popover"></popover>
  </section>
</template>

<script>
import Popover from "../components/Popover.vue";
import MFormDate from "../components/MFormDate.vue";
import MListSimple from "../components/MListSimple.vue";
import AttestationFiscale from "./AttestationFiscale.vue";
import CancelCharge from "./CancelChargeWinEdit.vue";
const IMAGE_MAX = 1024;
export default {
  name: "boxedetail",
  components: { CancelCharge, MFormDate, AttestationFiscale, MListSimple, Popover },
  data() {
    return {
      attestations: [],
      amountCancelCharge: Number,
      valueChargeRefunded: 0,
      valueChargeAmount: 0,
      valueIsDeposit: false,
      loadingPayments: false,
      loadingStripe: false,
      loadingTickets: false,
      subscriptionMethod: "",
      showDetailsBox: false,
      showMainDetailsBox: false,
      deletedAllMessages: false,
      mailParcelSend: false,
      filterInfoTech: true,
      filterInfoCom: true,
      row_en: { infos: { action: [] }, bo_id: {}, co_id: {} },
      row_bo: {},
      row_co: {},
      row_co_invoice: {},
      displayFormAttestationFiscale: false,
      //action
      newCommentAction: "",
      actions: [],
      action_length: 0,
      showActions: false,
      //parcel
      parcels: [],
      parcel_length: 0,
      dialogParcel: false,
      parcelSelected: {},
      //
      dialogMessage: false,
      dialogMessageTitle: "",
      dialogMessageTxt: "",
      //contact
      dialogBoxe: false,
      dialogBoxeLog: false,
      dialogContact: false,
      dialogEnduser: false,
      addMessage: false,
      newMessage: "",
      isAdmin: true,
      acknowledgment: false,
      duration: "00:00",
      // establishmentsSelected: {},
      // fileSelected: { image: null, binary: null },
      image: null,
      photo: true,
      imageSize: { width: 0, height: 0 },
      angle: 0,
      photoJoined: false,
      showMessages: false,
      messages: [],
      messages_length: 0,
      styleAvatar: "",
      // payments
      dialogConfirmCancelSubscription: false,
      dialogConfirmCancelSubscriptionNow: false,
      dialogConfirmCancelSubscriptionRib: false,
      dialogConfirmCancelCharge: false,
      dialogConfirmStartSubscription: false,
      dialogConfirmStartLoan: false,
      dialogConfirmStopLoan: false,
      dialogConfirmWaitingRefund: false,
      dialogVideoCall: false,
      displayVideoCall: false,
      dialogCancelCharge: false,
      callType: "video",
      subscriptionToDelete: null,
      subscriptionToDeleteObject: {},
      chargeToDelete: null,
      amountCharge: null,
      allSubscriptions: [],
      allCharges: [],
      orders: [],
      tickets: [],
      oldRib: false,
      actionDate: this.$moment().format("YYYY/MM/DD HH:mm:ss"),
      selectedActionType: "COMMENT",
      listActions: [
        { value: "PARCEL_PREORDER", text: "Box" },
        { value: "PARCEL_TO_PREPARE", text: "Box à préparer" },

        { value: "PARCEL_SENT", text: "Box envoyée" },
        { value: "PARCEL_SHIPPING_ERROR", text: "Erreur lors de la livraison de la box" },
        { value: "PARCEL_SENT_BACK_AFTER_FAILURE", text: "La box a été renvoyée après une erreur lors de la livraison" },

        { value: "PARCEL_RECEIVED", text: "Box réceptionnée" },
        { value: "PARCEL_CARRIED_AWAY", text: "Box emportée" },
        { value: "STRIPE_CREATE_CUSTOMER_FROM_WEB", text: "Création du client sur Stripe" },
        { value: "STRIPE_PAYMENT_DEPOSIT_SUCCESS", text: "Caution payée et validée" },
        { value: "STRIPE_CREATE_SUBSCRIPTION_FROM_WEB", text: "Caution payée, en cours de validation" },
        { value: "STRIPE_CREATE_SUBSCRIPTION_FROM_EXTRANET", text: "Démarrage de l'abonnement" },
        { value: "STRIPE_CREATE_SUBSCRIPTION_FROM_WEB_SECONDARYTRY", text: "Caution payée, en cours de validation, après l'inscription" },
        { value: "STRIPE_CANCEL_SUBSCRIPTION_FROM_WEB", text: "Demande d'annulation de l'abonnement - Action du client" },
        { value: "STRIPE_SUBSCRIPTION_PAYMENT_SUCCESS", text: "renouvellement de l'abonnement avec paiement validé" },
        { value: "STRIPE_CHANGE_CREDIT_CARD", text: "Nouvelle carte bancaire du client" },
        { value: "STRIPE_SUBSCRIPTION_PAYMENT_ERROR", text: "Erreur lors du renouvellement de l'abonnement, le paiement a échoué" },
        { value: "STRIPE_REFUND_CHARGE_FROM_EXTRANET", text: "Remboursement du client" },
        { value: "STRIPE_SUBSCRIPTION_PREP_CANCEL", text: "Annulation de l'abonnement après la période en cours" },
        { value: "STRIPE_SUBSCRIPTION_CANCELED", text: "Abonnement annulé" },
        { value: "RIB_CREATE_SUBSCRIPTION_FROM_EXTRANET", text: "Démarrage de l'abonnement - RIB" },
        { value: "COMMENT", text: "Commentaire" },
        { value: "LOANED_KIT", text: "Kit en prêt" }
      ],
      loanedKit: false,
      scheduleMessage: false,
      enduserSubscription: 0,
      displayModifySubscriptionStartDate: false,
      paymentMethods: [],
      activeSubscription: null,
      displayPaymentDetails: false,
      selectedPayment: null,
      activePayment: null,
      newSubscription: {},
      displayConfirmStartSubscriptionDate: false,
      dialogAlertAction: "",
      waitingSubscriptionStatus: "",
      waitingPaymentMethodStatus: "",
      disableGocardlessButton: false,
      disableGocardlessStartSubscriptionButton: false,
      disableGocardlessStopSubscriptionButton: false,
      gocardlessCharges: [],
      newSubscriptionStartDateMandate: null,
      cancelSubscriptionData: {},
      dialogConfirmSendEmailCancelSubscription: false,
      boxeDetailsTitlesDisplay: {
        bo_hardwareinfo: "Etat du boitier"
      },
      boxeDetailsDisplay: {
        //hardware
        appemotivi_version: "Numéro de version",
        status: "Statut",
        key4g_name: "Référence clé 4G",
        //button
        batterylevel: "Niveau de batterie du bouton (%)",
        appaired: "Associé",
        error: "Etat",
        //config sources
        source_emotivi: "Source Emotivi",
        source_emotivi_use_cec: "CEC utilisé pour basculer sur Emotivi",
        source_emotivi_use_ir: "Infrarouge utilisé pour basculer sur Emotivi",
        source_tv: "Source chaînes TV",
        source_tv_use_cec: "CEC utilisé pour basculer sur les chaînes TV",
        source_tv_use_ir: "Infrarouge utilisé pour basculer sur les chaînes TV",
        source_switch_on_use_cec: "CEC utilisé pour allumer le téléviseur",
        source_switch_on_use_ir: "Infrarouge utilisé pour allumer le téléviseur",
        source_switch_off_use_cec: "CEC utilisé pour éteindre le téléviseur",
        source_switch_off_use_ir: "Infrarouge utilisé pour éteindre le téléviseur",
        source_tvbrand: "Marque du téléviseur",
        current_tv_power_state: "Etat actuel du téléviseur",
        //config network
        upload: "Qualité du signal montant",
        download: "Qualité du signal descendant",
        //config_camera
        plugged: "Branchée",
        //options configurées
        options_value_leds_duration: "Durée (en secondes) d'allumage du bandeau lumineux après envoi d'un message",
        options_leds_disabled: "Désactivation du bandeau lumineux",
        options_display_connection_bar: "Affichage des identifiant/mot de passe sur le téléviseur du sénior",
        options_value_ringing_number: "Nombre de sonneries lors d'un appel",
        options_bistri_display_preview: "Affichage de l'aperçu du sénior sur son téléviseur",
        options_value_bistri_preview_valign: "Alignement vertical de l'aperçu du sénior sur son téléviseur",
        options_value_bistri_preview_halign: "Alignement horizontal de l'aperçu du sénior sur son téléviseur",
        options_value_bistri_preview_size: "Taille de l'aperçu du sénior sur son téléviseur"
      },
      networkSignalStatusTranslation: {
        average: "moyen",
        good: "bon",
        low: "faible"
      },
      paymentMethodsName: {
        rib: "SEPA",
        cb: "CB"
      }
    };
  },

  watch: {
    async showMessages(val) {
      if (val) {
        let params = { en_id: this.row_en.en_id };
        let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/messages", {
          params
        });
        if (!response || !response.data) return;
        this.messages = response.data.data;
        // console.log("this.messages", this.messages);
        this.messages_length = response.data.meta.total;
        this.styleAvatar = "background-image:url('" + val + "')";
      }
    },
    async socketResponse(val) {
      // console.log("val", val);
      if (val && val.cmd === "network-quality/ok") {
        this.dialogMessageTitle = "Diagnostique réseau";
        this.dialogMessageTxt = val.data;
        this.dialogMessage = true;
      }
    },
    actions(val) {
      if (val && val.length) {
        this.loanedKit = false;
        val.forEach(action => {
          if (action.en_id && action.en_id.en_id && this.row_en.en_id === action.en_id.en_id && action.ac_type === "LOANED_KIT") {
            // changer le bouton Kit en prêt par Restituer le kit et afficher que le kit est en prêt dans les détails
            this.loanedKit = true;
          }
        });
      }
    },
    "row_en.en_send_cancel_sub_email": {
      handler(val, oldVal) {
        //console.log(val, oldVal);

        if (val !== undefined && oldVal !== undefined && val !== oldVal) {
          // console.log("save option");
          this.saveOptionSendCancelSubMail();
        }
      }
    },
    getGocardlessSubscriptionStates(value, oldValue) {
      if (value && value.en_id === this.row_en.en_id && value.subscription) {
        if (this.subscriptionStatusText[value.subscription.status]) this.updateSpinner(this.subscriptionStatusText[value.subscription.status]);

        if (this.waitingSubscriptionStatus === value.subscription.status) {
          this.loadPayments()
            .then(() => {
              this.waitingSubscriptionStatus = "";
            })
            .catch(error => {
              alert("Une erreur est survenue lors de la récupération des abonnements en base de données. Veuillez recharger la page.");
              this.waitingSubscriptionStatus = "";
            });
        }
      }
    },
    getGocardlessPaymentmethodStates(value, oldValue) {
      if (value && value.en_id === this.row_en.en_id && value.paymentmethod) {
        if (this.paymentMethodsStatusText[value.paymentmethod.pm_status])
          this.updateSpinner(this.paymentMethodsStatusText[value.paymentmethod.pm_status]);
        if (this.waitingPaymentMethodStatus === value.paymentmethod.pm_status) {
          this.loadPayments()
            .then(() => {
              this.waitingPaymentMethodStatus = "";
            })
            .catch(error => {
              alert("Une erreur est survenue lors de la récupération des modes de paiements en base de données. Veuillez recharger la page.");
              this.waitingPaymentMethodStatus = "";
            });
        }
      }
    }
  },
  async mounted() {
    await this.loadAll();
    if (this.$route.query.gocardless) {
      /** redirected from gocardless billing request */

      if (this.$route.query.gocardless === "pm_cancelled") {
        this.dialogMessageTitle = "Mode de paiement GOCARDLESS";
        this.dialogMessageTxt = "L'enregistrement du mode de paiement pour l'utilisateur final " + this.row_en.en_fullname + " a été annulé.";
        this.dialogMessage = true;
        /** avoid to display again the dialog if user reload the page */
        this.$router.push({ query: { gocardless: "" } });
      }
      if (this.$route.query.gocardless === "waiting_pm_states") {
        this.waitingPaymentMethodStatus = "fulfilled";
        /** avoid to display again the payment method waiting spinner if user reload the page */
        this.$router.push({ query: { gocardless: "" } });
      }
      /** this query data is set from billing request on existing customer - update mandate  */
      if (this.$route.query.gocardless === "waiting_pm_states_update") {
        this.waitingPaymentMethodStatus = "fulfilled";
        /** avoid to display again the payment method waiting spinner if user reload the page */
        this.$router.push({ query: { gocardless: "" } });
      }
    }
  },
  computed: {
    co_invoice_address() {
      let ad = [this.row_co.co_invoice_address1];
      if (this.row_co.co_invoice_address2) ad.push(this.row_co.co_invoice_address2);
      ad.push(this.row_co.co_invoice_zip);
      ad.push(this.row_co.co_invoice_city);
      return ad.join(" ");
    },
    co_shipping_address() {
      let ad = [this.row_co.co_shipping_address1];
      if (this.row_co.co_shipping_address2) ad.push(this.row_co.co_shipping_address2);
      ad.push(this.row_co.co_shipping_zip);
      ad.push(this.row_co.co_shipping_city);
      return ad.join(" ");
    },
    tabsInfos() {
      let tabsInfos = [
        { key: "bo_hardwareinfo", value: "Informations matériel" },
        { key: "bo_config_button", value: "Informations sur le bouton" },
        { key: "bo_config_sources", value: "Configuration des sources" },
        { key: "bo_config_network", value: "Configuration réseau" },
        { key: "bo_config_camera", value: "Informations sur la caméra" },
        { key: "bo_config_options", value: "Options configurées" }
      ];
      return tabsInfos;
    },
    socketResponse() {
      return this.$store.state.socket_response;
    },
    durationToSecond() {
      return this.$moment.duration("00:" + this.duration).asSeconds() * 1;
    },
    subscriptionStartDate: {
      get() {
        return this.activeSubscription && this.activeSubscription.su_startdate
          ? this.activeSubscription.su_startdate
          : this.$moment().format("YYYY-MM-DD");
      },
      set(value) {
        this.activeSubscription.su_startdate = this.$moment(value).format("YYYY-MM-DD");
      }
    },
    newSubscriptionStartDate: {
      get() {
        return this.newSubscriptionStartDateMandate || this.$moment().format("YYYY-MM-DD");
      },
      set(value) {
        this.newSubscription.su_startdate = this.$moment(value).format("YYYY-MM-DD");
      }
    },
    getGocardlessSubscriptionStates() {
      return this.$store.state.get_gocardless_subscription_response;
    },
    getGocardlessPaymentmethodStates() {
      return this.$store.state.get_gocardless_paymentmethod_response;
    },
    paymentMethodsStatusText() {
      return this.$store.state.paymentMethodsStatusText;
    },
    subscriptionStatusText() {
      return this.$store.state.subscriptionStatusText;
    },
    paymentsStatusText() {
      return this.$store.state.paymentsStatusText;
    },
    dialogConfirmStartSubscriptionText() {
      return `Souhaitez-vous commencer un abonnement ${this.subscriptionMethod.toUpperCase()} ?`;
    },
    boxId() {
      return this.row_bo && this.row_bo.bo_id ? this.row_bo.bo_id : 0;
    }
  },
  methods: {
    async reinitBoxe() {
      try {
        let ok = window.confirm("Voulez-vous réinitialiser la boxe ?");
        if (ok) {
          let response = await this.$axios.post(this.$config.server_url + "/extranet/2.0/resetbox", {
            en_id: this.row_en.en_id,
            bo_id: this.row_bo.bo_id
          });
          if (response.statusText == "OK") {
            alert("La boxe a été réinitialisée.");
          } else {
            alert("Ereur. La boxe n'a pas été réinitialisée.");
          }
        } else {
          alert("La boxe n'a pas été réinitialisée.");
        }
      } catch (error) {
        console.error(error);
      }
    },
    async updateOrderStatus(order) {
      try {
        if (order.or_status === "UNPAID") {
          if (!confirm("Vous allez passer ce paiement en état 'payé'. Êtes-vous sûr ?")) return;
          order.or_status = "PAID";
        } else if (order.or_status === "PAID") {
          if (!confirm("Vous allez passer ce paiement en état 'non payé'. Êtes-vous sûr ?")) return;
          order.or_status = "UNPAID";
        } else return alert("Une erreur est survenue.");

        let orderline = await this.$axios.get(this.$config.server_url + "/extranet/2.0/orderlines/byorderid/" + order.or_id);
        order.orderlines = [orderline];

        await this.$axios.put(this.$config.server_url + "/extranet/2.0/orders/" + order.or_id, order);
      } catch (error) {
        alert("Une erreur est survenue : " + error);
      }
    },
    async loadAll() {
      await this.loadBoxeEnduser();
      if (this.$store.getters.checkUserAccess(this.$router, "/invoices")) await this.loadPayments();
      this.getParcels();
      await this.loadActions();
      await this.loadAttestations();
    },
    // openDetailBox() {
    //   this.showDetailsBox = !this.showDetailsBox;
    //   console.log("this.showDetailsBox", this.showDetailsBox);
    // },
    // valueToText(value, dataStore) {
    //   return this.$_.result(this.$_.find(dataStore, { value: value }), "text", value);
    // },
    async deleteAllMessages() {
      if (window.confirm("Souhaitez-vous effacer tous les messages de cette box ?")) {
        await this.$axios.delete(this.$config.server_url + "/extranet/2.0/messages/all/" + this.row_en.en_id);
        this.deletedAllMessages = true;
      }
    },
    allinfos(what) {
      // console.log("what", what);
      let allinfos = [];
      let ignore = ["en_webapp_login", "en_webapp_password", "en_admincode"];
      if (!this.row_bo) return [];
      this.$_.each(this.row_bo[what], (val, key) => {
        if (this.$_.indexOf(ignore, key) >= 0) return;
        let keytranslation = this.boxeDetailsDisplay[key] ? this.boxeDetailsDisplay[key] : key;
        allinfos.push({ key: keytranslation, val });
      });
      return allinfos;
    },
    maininfos(what) {
      let maininfos = [];
      let ignore = [
        "en_webapp_login",
        "en_webapp_password",
        "en_admincode",
        "hardware_name",
        "hardware_version",
        "hardware_serial",
        "hardware_memory",
        "ethernet_mac_address",
        "wifi_mac_address",
        "hardware_button_name",
        "hardware_button_version",
        "hardware_camera_name",
        "hardware_camera_version",
        "hardware_ircard_name",
        "hardware_ircard_version",
        "hardware_leds_name",
        "hardware_leds_version",
        "hardware_resolution",
        "output_volume",
        "input_volume",
        "key4g_version",
        "hardware_eeprom",
        "bluetoothmacaddress",
        "light",
        "source_tv_model",
        "source_ir_tv",
        "source_ir_emotivi",
        "source_ir_sound_plus",
        "source_ir_sound_minus",
        "source_ir_switch_on",
        "source_ir_switch_off",
        "source_ir_duration",
        "source_ir_impulse",
        "source_ir_repeat",
        "source_ir_delay",
        "source_tv_macro",
        "source_emotivi_macro",
        "source_ir_impulsing",
        "source_ignorecec",
        "source_cec_delay",
        "current_supposed_source",
        "current_supposed_displayed_hdmi_port",
        "current_supposed_source_physical_address",
        "tv_device",
        "cec_box_version",
        "cec_tv_version",
        "cec_tv_vendor",
        "emotivi_device",
        "all_devices",
        "pingGateway",
        "configuration",
        "diagnostic",
        "complete",
        "options_value_button_functionality"
      ];
      if (!this.row_bo) return [];
      this.$_.each(this.row_bo[what], (val, key) => {
        if (this.$_.indexOf(ignore, key) >= 0) return;
        let keytranslation = this.boxeDetailsDisplay[key] ? this.boxeDetailsDisplay[key] : key;
        maininfos.push({ key: keytranslation, val: this.translateBoxeDetailsValue(val, key) });
      });
      return maininfos;
    },
    translateBoxeDetailsValue(value, key) {
      if (value === true) return "oui";
      else if (value === false) return "non";
      else if (value === "other") return "autre";
      else if (value === "btn_not_found") return "bouton non appairé";
      else if (value === "on") return "allumé";
      else if (value === "off") return "éteint";
      else if (value === "top") return "en haut";
      else if (value === "bottom") return "en bas";
      else if (value === "left") return "à gauche";
      else if (value === "right") return "à droite";
      else if (value === "camera_not_found") return "caméra non détectée";
      else if (key === "upload" || key === "download") return this.networkSignalStatusTranslation[value.status];
      else return value;
    },
    async loadBoxeEnduser() {
      // load enduser
      let params = {};
      this.cancelAxiosLoadContacts && this.cancelAxiosLoadContacts();
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/endusers/" + this.$route.params.en_id * 1, {
        params,
        cancelToken: new this.$axios.CancelToken(c => {
          this.cancelAxiosLoadContacts = c;
        })
      });
      if (!response || !response.data) return;
      this.row_en = response.data.data;
      if (response.data.data.co_id) {

        this.row_co = response.data.data.co_id;
        this.row_co_invoice = Object.assign({}, response.data.data.co_id);

        if(response.data.data.en_invoice_payer === "ENDUSER") { 
          this.row_co_invoice.co_invoice_name = response.data.data.en_fullname;
          this.row_co_invoice.co_invoice_firstname = "";
        }

        this.loadTickets();
      }
      if (response.data.data.bo_id) this.row_bo = response.data.data.bo_id;
    },
    /**
     * loadPayments - get all payments from stripe and gocardless + get subscriptions and orders
     *
     */
    async loadPayments() {
      this.allSubscriptions = [];
      this.gocardlessCharges = [];
      this.orders = [];
      if (!this.row_co) return;

      /** get all payments and subscriptions data from API */
      this.loadingPayments = true;
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/endusers/payments/" + this.row_en.en_id);
      if (response.data.err) {
        console.error("loadPayments error > ", response.data.err);
        this.loadingPayments = false;
        return;
      }

      /** get subscriptions and active subscription */
      if (response.data && response.data.data && response.data.data.subscriptions) {
        this.allSubscriptions = response.data.data.subscriptions;
        this.oldRib = response.data.data.oldRib;
        this.activeSubscription = this.allSubscriptions.find(subscription => {
          return subscription.su_state === 2;
        });
      } else {
        this.allSubscriptions = [];
        this.oldRib = false;
        this.activeSubscription = null;
      }

      /** get orders */
      if (response.data && response.data.data && response.data.data.rows_or) {
        this.orders = response.data.data.rows_or;
      } else {
        this.orders = [];
      }

      /** get payment methods */
      if (response.data && response.data.data && response.data.data.rows_pm) {
        this.paymentMethods = response.data.data.rows_pm;
        this.paymentMethods.forEach(payment => {
          if (payment.pm_active) this.activePayment = payment;
        });
      } else {
        this.paymentMethods = [];
      }

      this.allCharges = response.data && response.data.data && Array.isArray(response.data.data.charges) ? response.data.data.charges : [];
      this.loadingPayments = false;
    },
    async loadTickets() {
      this.tickets = [];
      this.loadingTickets = true;
      let params = { email: this.row_co.co_invoice_email };
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/tickets", { params });
      if (response.data.data && this.$_.isArray(response.data.data)) this.tickets = response.data.data;
      this.loadingTickets = false;
      // console.log("this.tickets", this.tickets);
    },
    async loadAttestations() {
      this.loadingStripe = true;
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/attestationFiscale", {
        params: {
          en_id: this.row_en.en_id
        }
      });
      this.loadingStripe = false;
      if (!response || !response.data || response.data.error || !response.data.data) return;
      this.attestations.push(response.data.data);
    },
    goFreshdeskTicket(item) {
      window.open("https://emotivi.freshdesk.com/a/tickets/" + item.id, "_blank");
    },
    async printOrder(item, $event) {
      $event.stopPropagation();
      //console.log("item", item);
      window.open(
        this.$config.server_url + "/extranet/2.0/orders/" + item.or_id + "/pdf?d=" + new Date().getTime() + "&token=" + this.$store.state.accesstoken,
        "_blank"
      );
    },
    async sendInvitationEmail() {
      let boby = {
        co_id: this.row_co.co_id,
        en_id: this.row_en.en_id
      };
      let response = await this.$axios.post(this.$config.server_url + "/extranet/2.0/endusers/activation", boby);
      if (response.data.data && response.data.data.success) {
        this.dialogMessageTitle = "Confirmation";
        this.dialogMessageTxt = "Les identifiants ont bien été renvoyés";
      } else {
        this.dialogMessageTitle = "Erreur";
        this.dialogMessageTxt = "Une erreurs s'est produite, le mail n'a pas été envoyé";
      }
      this.dialogMessage = true;
    },
    async startSubscription() {
      if (this.subscriptionMethod === "gocardless") return this.gocardless();
      let response = await this.$axios.post(
        this.$config.server_url + "/extranet/2.0/startsubscription/" + this.row_en.en_id + "/" + this.subscriptionMethod,
        this.newSubscription
      );
      // console.log("response", response);
      this.dialogConfirmStartSubscription = false;
      this.displayConfirmStartSubscriptionDate = false;
      if (response && response.data && response.data.err && response.data.err.key === "subscription_exist")
        return alert("Un abonnement est en cours, veuillez l'annuler avant d'en démarrer un nouveau.");
      await this.loadBoxeEnduser();
      this.loadPayments();
      this.displayConfirmStartSubscriptionDate = false;
    },
    async updateSubscription(data) {
      // let response =
      try {
        let response = await this.$axios.put(this.$config.server_url + "/extranet/2.0/subscriptions/" + this.activeSubscription.id, data);
        if (!response.data || !response.data.data || !response.data.data.success)
          alert("Une erreur est survenue lors de la mise  à jour de l'abonnement");
        await this.loadBoxeEnduser();
        await this.loadPayments();
      } catch (error) {
        alert("Une erreur est survenue lors de la mise  à jour de l'abonnement");
      }
    },
    async startLoan() {
      // let response =
      await this.$axios.get(this.$config.server_url + "/extranet/2.0/startloan/" + this.row_en.en_id);
      await this.loadActions();
      // console.log("response", response);
      this.dialogConfirmStartLoan = false;
    },
    async stopLoan() {
      // let response =
      await this.$axios.get(this.$config.server_url + "/extranet/2.0/stoploan/" + this.row_en.en_id);
      await this.loadActions();
      // console.log("response", response);
      this.dialogConfirmStopLoan = false;
    },
    async startWaitingRefund() {
      // let response =
      await this.$axios.get(this.$config.server_url + "/extranet/2.0/startwaitingrefund/" + this.row_en.en_id);
      // console.log("response", response);
      this.dialogConfirmWaitingRefund = false;
      this.loadPayments();
    },
    confirmCancelSubscription(sub_id) {
      this.subscriptionToDelete = sub_id;
      this.dialogConfirmCancelSubscription = true;
    },
    confirmCancelSubscriptionRib(sub_id, subscriptionObject) {
      if (!sub_id && !subscriptionObject) {
        this.allSubscriptions.forEach(subscription => {
          if (
            subscription.su_paymentmethod === "rib" &&
            subscription.end_user &&
            Array.isArray(subscription.end_user) &&
            subscription.end_user.length &&
            subscription.end_user[0].en_id === this.row_en.en_id
          ) {
            this.subscriptionToDelete = subscription.id;
            this.subscriptionToDeleteObject = subscription;
            this.dialogConfirmCancelSubscriptionRib = true;
          }
        });
      } else {
        this.subscriptionToDelete = sub_id;
        this.subscriptionToDeleteObject = subscriptionObject;
        this.dialogConfirmCancelSubscriptionRib = true;
      }
    },
    confirmCancelSubscriptionGocardless(sub_id, subscriptionObject) {
      this.subscriptionToDelete = sub_id;
      this.subscriptionToDeleteObject = subscriptionObject;
      this.dialogConfirmCancelSubscription = true;
    },
    getColor(type) {
      if (type === "CLE4G_EMOTIVI" || type === "ROUTEUR4G_EMOTIVI") {
        return "red";
      }
    },
    getColorStatus(status) {
      if (
        status === "NOT_INSTALLED" ||
        status === "RETURNED" ||
        status === "NOT_PAYED" ||
        status === "REFUND_WAITING" ||
        status === "REFUNDED_PARTIALLY" ||
        status === "WAITING" ||
        status === "SHIPPING_ERROR" ||
        status === "SENT_BACK_AFTER_FAILURE" ||
        status === "TO_PREPARE" ||
        status === "TO_SEND" ||
        status === "TO_PREPARE_KEY4G" ||
        status === "WAITING_SUBS" ||
        status === "RIB_TO_CONFIRM" ||
        status === "RIB_PROBLEM" ||
        status === "CB_PROBLEM" ||
        status === "WAITING_STOPPED" ||
        status === "STOPPED" ||
        status === "NOT_TO_START" ||
        status === "SUSPENDED" ||
        status === "TO_DEFINE" ||
        status === "CONNEXION" ||
        status === "SOURCE" ||
        status === "BUTTON" ||
        status === "DISPLAY" ||
        status === "SOUND" ||
        status === "MATERIAL"
      ) {
        return "color:red;";
      }
      return "";
    },
    getBold(type) {
      if (type === "CLE4G_EMOTIVI" || type === "ROUTEUR4G_EMOTIVI") {
        return "font-weight-bold";
      }
    },

    confirmCancelCharge(cha_id) {
      this.chargeToDelete = cha_id;
      this.dialogConfirmCancelCharge = true;
    },
    confirmStartSubscription() {
      this.dialogConfirmStartSubscription = true;
    },
    async confirmStartSubscriptionDate(method) {
      this.subscriptionMethod = method;
      this.displayConfirmStartSubscriptionDate = !this.displayConfirmStartSubscriptionDate;
      if (method === "gocardless" && this.activePayment.pm_gocardless_ids && this.activePayment.pm_gocardless_ids.mandate_request_mandate) {
        /** get mandate next possible date charge, to set automtically the start date input */

        try {
          let response = await this.$axios.get(
            this.$config.server_url +
              "/extranet/2.0/gocardless/mandate/next-possible-charge/" +
              this.activePayment.pm_gocardless_ids.mandate_request_mandate
          );
          if (response.data && response.data.data && response.data.data.next_possible_charge_date) {
            this.newSubscriptionStartDateMandate = this.$moment(response.data.data.next_possible_charge_date).format("YYYY-MM-DD");
            this.newSubscriptionStartDate = response.data.data.next_possible_charge_date;
          }
        } catch (error) {
          console.error("confirmStartSubscriptionDate > ", error);
        }
      }
    },
    confirmStartLoan() {
      this.dialogConfirmStartLoan = true;
    },
    confirmStopLoan() {
      this.dialogConfirmStopLoan = true;
    },
    confirmWaitingRefund(method) {
      this.dialogConfirmWaitingRefund = true;
    },
    async saveOptionSendCancelSubMail() {
      try {
        let response = await this.$axios.put(this.$config.server_url + "/extranet/2.0/endusers/" + this.row_en.en_id, {
          en_send_cancel_sub_email: this.row_en.en_send_cancel_sub_email
        });
        if (response && response.data && response.data.err) {
          console.error(response.data.err);
          alert("Une erreur est survenue.");
        }
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * cancelSubscription
     * @params now ( '' or '?now=ok' )
     */
    async cancelSubscription(now) {
      try {
        this.dialogConfirmCancelSubscriptionNow = false;

        // if subscription to cancel use GoCardless
        if (this.subscriptionToDeleteObject.subscriptionData && this.subscriptionToDeleteObject.subscriptionData.su_gocardlessid) {
          this.disableGocardlessStopSubscriptionButton = true;
          let response = await this.$axios.delete(
            this.$config.server_url + "/extranet/2.0/gocardless/subscription/cancel/" + this.row_en.en_id + "/" + this.subscriptionToDelete + now,
            { data: this.cancelSubscriptionData }
          );
          if (response && response.data && response.data.err) {
            if (response.data.err.key === "subscription_already_cancelled") alert("L'abonnement est déjà annulé auprès de Gocardless.");
            this.disableGocardlessStopSubscriptionButton = false;
            return;
          }
          if (now) this.waitingSubscriptionStatus = "cancelled";
          this.disableGocardlessStopSubscriptionButton = false;
        }
        // otherwise, we assume the subscription to cancel use Stripe
        else {
          await this.$axios.delete(
            this.$config.server_url + "/extranet/2.0/stripe/subscription/" + this.row_en.en_id + "/" + this.subscriptionToDelete + now,
            { data: this.cancelSubscriptionData }
          );
        }

        // console.log("response", response);
        await this.loadBoxeEnduser();
        await this.loadPayments();
      } catch (error) {
        console.error(error);
      }
    },
    textMessage(nbre) {
      const message = "Vous avez reçu " + nbre + " nouveau(x) message(s)";
      return message;
    },
    /**
     * @params dataFromSelectDialog ( 0 === inactive, 1 === waiting for cancelation )
     */
    async cancelSubscriptionRib(dataFromSelectDialog) {
      let body = {};
      // RIB subscription
      body.su_state = dataFromSelectDialog;

      await this.$axios.delete(this.$config.server_url + "/extranet/2.0/stripe/subscription/" + this.row_en.en_id + "/" + this.subscriptionToDelete, {
        data: body
      });
      // console.log("response", response);
      this.dialogConfirmCancelSubscriptionRib = false;
      await this.loadBoxeEnduser();
      await this.loadPayments();
    },
    cancelCharge(montant) {
      //let response =
      this.amountCharge = montant;
      this.dialogCancelCharge = false;
      // console.log("montant:", this.amountCharge);
      this.dialogConfirmCancelCharge = true;
    },
    async cancelChargeConfirmed() {
      // console.log("Confirmation appelée:");
      await this.$axios.delete(
        this.$config.server_url + "/extranet/2.0/stripe/charge/" + this.row_en.en_id + "/" + this.chargeToDelete + "/" + this.amountCharge + (this.valueIsDeposit ? "?deposit=1" : "")
      );
      // console.log("response", response);
      this.dialogConfirmCancelCharge = false;
      this.loadPayments();
    },
    showConfigPage() {
      window.open(this.$config.config_url + "/#/login/" + this.row_en.en_accesstoken_fromconfig);
    },
    async showAnimation() {
      // let response =
      await this.$axios.get(this.$config.server_url + "/extranet/2.0/do/start-animation/" + this.row_en.en_id);
      // console.log("response", response);
    },
    deleteFiles() {
      if (this.photo) {
        //document.querySelector(".containerToHide").style.display = "block";
        document.querySelector(".trash").style.display = "none";
        document.querySelector(".rotate-left").style.display = "none";
        document.querySelector(".rotate-right").style.display = "none";
        this.image.src = "";
        this.image = null;
        this.photoJoined = false;
      }
    },
    handleFiles() {
      if (this.$refs.myFiles.files.length == 0) return;
      let file = this.$refs.myFiles.files[0];
      // console.log("file :>> ", file);
      let isImage = /^image\//.test(file.type);

      //  //let isVideo = /^video\//.test(file.type);
      //  this.fileSelected.image = $event[0];
      //  let isImage = /^image\//.test(this.fileSelected.image.type);

      //console.log("image size : ", file.size);
      // taille des médias bloquée à 5,1Mo.
      // if (file.size > 5064986) return alert(this.lang === "fr" ? this.pageText.alertImage_tooLarge.fr : this.pageText.alertImage_tooLarge.en);
      if (!isImage) {
        // return alert(this.lang === "fr" ? this.pageText.alertImage.fr : this.pageText.alertImage.en);
        //   this.fileSelected = { image: null, binary: null };
        //   this.deleteFiles();
        //   return;
      }
      if (document.querySelector("#media-to-send")) document.querySelector("#media-to-send").remove();
      let img = new Image();
      img.style.width = "100%";
      img.style.border = "1px solid #868b90";
      img.id = "media-to-send";
      this.photo = isImage ? true : false;
      let btn = document.querySelectorAll(".btn-img");
      btn.forEach(function (el) {
        el.style.display = "block";
      });
      this.photoJoined = true;
      // this.showFile(img, file);
      // let img = new Image();
      const fileReader_base64 = new FileReader();
      fileReader_base64.onload = e => {
        img.src = fileReader_base64.result;
        setTimeout(() => {
          this.image = img;
          this.imageSize.width = img.width;
          this.imageSize.height = img.height;
          this.$refs.preview.appendChild(img);
          let { w, h } = this._calculateWidthHeightWithMax(IMAGE_MAX);
          let ctx = this.$refs.canvas.getContext("2d");
          ctx.canvas.width = w;
          ctx.canvas.height = h;
          ctx.drawImage(img, 0, 0, w, h);
        }, 500);
      };
      fileReader_base64.readAsDataURL(file);
    },
    rotateFiles(event, sens) {
      this.angle = sens === "right" ? this.angle + 90 : this.angle - 90;
      if (this.angle === 360 || this.angle === -360) this.angle = 0;
      let max = this.$refs.previewContainer.offsetWidth;
      let translateY = 0,
        w = 0,
        h = 0;
      let { w: wOri, h: hOri } = this._calculateWidthHeightWithMax(IMAGE_MAX);
      let ctx = this.$refs.canvas.getContext("2d");
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      ctx.save();

      if (this.angle === 90 || this.angle === -90 || this.angle === 270 || this.angle === -270) {
        w = Math.ceil(max / (this.imageSize.height / this.imageSize.width));
        h = max;
        translateY = (w - h) / 2;
        this.$refs.previewContainer.style.height = w + "px";
        ctx.canvas.width = hOri;
        ctx.canvas.height = wOri;
        ctx.translate(ctx.canvas.width / 2, ctx.canvas.height / 2);
        ctx.rotate((this.angle * Math.PI) / 180);
        ctx.drawImage(this.image, -ctx.canvas.width / 2, -ctx.canvas.height / 2, wOri, hOri);
      }
      if (this.angle === 0 || this.angle === 180 || this.angle === -180) {
        w = max;
        h = Math.ceil(max / (this.imageSize.width / this.imageSize.height));
        this.$refs.previewContainer.style.height = h + "px";
        ctx.canvas.width = wOri;
        ctx.canvas.height = hOri;
        ctx.translate(ctx.canvas.width / 2, ctx.canvas.height / 2);
        ctx.rotate((this.angle * Math.PI) / 180);
        ctx.drawImage(this.image, -ctx.canvas.width / 2, -ctx.canvas.height / 2, ctx.canvas.width, ctx.canvas.height);
      }
      document.querySelector("#media-to-send").style.width = w + "px";
      document.querySelector("#media-to-send").style.height = h + "px";
      this.$refs.preview.style.transform = "translate(0," + translateY + "px) rotate(" + this.angle + "deg)";

      ctx.restore();
    },
    _calculateWidthHeightWithMax(max) {
      let w = this.imageSize.width,
        h = this.imageSize.height;
      if (this.imageSize.width > this.imageSize.height) {
        w = max;
        h = Math.ceil(max / (this.imageSize.width / this.imageSize.height));
      } else {
        w = Math.ceil(max / (this.imageSize.height / this.imageSize.width));
        h = IMAGE_MAX;
      }
      return { w, h };
    },
    async sendMessage() {
      // if (!this.row_en.co_id.es_id && !this.row_en.co_id.es_id.es_id) return;
      // let data = {
      //   avatar_uuid: this.row_en.co_id.es_id.es_id,
      //   en_id: this.row_en.en_id,
      //   me_text: this.newMessage,
      //   me_type: "TEXT",
      //   me_pseudo: this.row_co.es_id.es_name,
      //   me_avatar: this.row_co.es_id.es_avatar
      // };
      // // let response =
      // await this.$axios.post(this.$config.server_url + "/extranet/2.0/messages", data);
      // // this.fileSelected = { image: null, binary: null };
      // // console.log("response", response);
      if (!this.row_en.co_id.es_id && !this.row_en.co_id.es_id.es_id) return;
      if (!this.newMessage && !this.image) return; // || (!this.photo && !this.messageVideoData)
      let dataMess = {
        me_type: this.image ? "PHOTO" : "TEXT",
        me_text: this.newMessage,
        me_isAdmin: this.isAdmin,
        me_duration: this.durationToSecond,
        me_acknowledgment: this.acknowledgment,
        avatar_uuid: this.row_en.co_id.es_id.es_id,
        en_id: this.row_en.en_id,
        me_pseudo: this.row_co.es_id.es_name,
        me_avatar: this.row_co.es_id.es_avatar
      };
      try {
        let response;
        response = await this.$axios.post(this.$config.server_url + "/extranet/2.0/messages", dataMess);
        if (response.data.err) {
          this.$store.dispatch("showDialogError", response.data.err.message);
          return;
        }
        let rows_me = response.data.data;
        if (this.photoJoined) {
          // from webapp
          let dataMedia = {
            rows_me: rows_me,
            media: this.$refs.canvas.toDataURL("image/jpeg", 1.0) //row_me.me_type === "VIDEO" ? this.videoBase64 : this.imageBase64
          };
          await this.$axios.post(this.$config.server_url + "/extranet/2.0/messages/media", dataMedia);
        }
        // console.log("response", response);
        this.resetData();
      } catch (error) {
        console.error(error);
      }
    },
    resetData() {
      this.image = null;
      this.photo = true;
      this.newMessage = "";
      this.isAdmin = true;
      //  this.messageVideoData = null;
      this.photoJoined = false;
      if (document.querySelector("#media-to-send")) document.querySelector("#media-to-send").remove();
      this.fileSelected = { image: null, binary: null };
      this.styleAvatar = "";
      // this.establishmentsSelected = {};
      this.duration = "00:00";
      this.acknowledgment = false;
    },
    fileJusteSelected($event) {
      this.fileSelected.image = $event[0];
      var reader = new FileReader();
      var me = this;
      reader.onload = (function (theFile) {
        return function (e) {
          me.fileSelected.binary = e.target.result;
        };
      })($event[0]);
      reader.readAsDataURL($event[0]);
    },
    async diagnostikReseau() {
      // send socket event /extranet/2.0/network-quality
      this.$store.dispatch("socketEmit", { cmd: "network-quality", data: { bo_mac_address: this.row_bo.bo_mac_address } });
      // wait for the event /extranet/2.0/network-quality/ok

      // this.dialogDiagnostiqueReseauTxt = JSON.stringify(this.row_bo.bo_config_network);
      // this.dialogDiagnostiqueReseau = true;
    },
    ///les parcels
    followParcel(item) {
      navigator.clipboard.writeText(item.pa_trackingcode).then(
        function () {
          window.open("https://www.laposte.fr/outils/suivre-vos-envois");
        },
        function () {
          window.open("https://www.laposte.fr/outils/suivre-vos-envois");
        }
      );
    },
    copieClipboard(item) {
      // console.log("item", item);
      //navigator.clipboard.writeText(item);
    },
    parcelSaved() {
      this.getParcels();
      this.loadBoxeEnduser();
    },
    newParcel() {
      this.parcelSelected = {};
      this.$router.push("/parcels/" + this.row_en.en_id);
      this.dialogParcel = true;
    },
    editParcel(item) {
      this.parcelSelected = item;
      this.$router.push("/parcels/" + this.row_en.en_id + "/edit/" + item.pa_id);
      this.dialogParcel = true;
    },
    async mailParcel(item) {
      await this.$axios.get(this.$config.server_url + "/extranet/2.0/parcels/mail/" + item.pa_id);
      this.mailParcelSend = true;
    },
    async getParcels() {
      let params = { en_id: this.row_en.en_id };
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/parcels", {
        params
      });
      if (!response || !response.data) return;
      this.parcels = response.data.data;
      this.parcel_length = response.data.meta ? response.data.meta.total : 0;
    },
    //Formulaire montant cancel charge
    newCancelCharge(cha_id) {

      this.chargeToDelete = cha_id;
      for (let ich = 0; ich < this.allCharges.length; ich++) {
        const ch = this.allCharges[ich];
        if (ch.payment_intent == cha_id) {
          this.valueChargeAmount = ch.amount;
          this.valueChargeRefunded = ch.amount_refunded;
          this.valueIsDeposit = ch.amount === (100 * 100);
        }
      }
      this.dialogCancelCharge = true;
    },
    // les actions
    async loadActions(showList) {
      if (showList) {
        if (this.showActions) {
          this.showActions = false;
          return;
        }
        this.showActions = true;
      }

      let params = { en_id: this.row_en.en_id, sort: "t1.createdAt desc" };
      let response2 = await this.$axios.get(this.$config.server_url + "/extranet/2.0/actions", {
        params
      });
      if (!response2 || !response2.data) return;
      this.actions = response2.data.data;
      // console.log("this.actions", this.actions);
      this.action_length = response2.data.meta.total;
      if (showList) this.showActions = true;
    },
    async addCommentAction() {
      if (!this.newCommentAction && this.selectedActionType === "COMMENT")
        return (this.dialogAlertAction = "Veuillez renseigner un commentaire avant de pouvoir l'ajouter");
      let newAction = {
        ac_type: this.selectedActionType,
        ac_comment: this.newCommentAction,
        ac_done: false,
        ac_date: this.actionDate,
        en_id: this.row_en.en_id,
        us_id: this.$store.state.user.us_id
      };
      await this.$axios.post(this.$config.server_url + "/extranet/2.0/actions", newAction);
      this.showActions = false;
      this.loadActions("showList");
      this.newCommentAction = "";
      this.dialogAlertAction = "";
    },
    //la box
    editBoxe(item) {
      this.$router.push("/boxes/" + this.row_en.en_id + "/edit");
      this.dialogBoxe = true;
    },
    displayBoxLogs(item) {
      this.$router.push("/boxes/" + this.row_en.en_id + "/displayLogs");
      this.dialogBoxeLog = true;
    },
    boxeSaved() {
      this.loadBoxeEnduser();
      this.$router.push("/boxes/" + this.row_en.en_id);
    },
    boxeCancel() {
      this.$router.push("/boxes/" + this.row_en.en_id);
    },
    //le contact et enduser
    editContact() {
      this.dialogContact = true;
      this.$router.push("/boxes/" + this.row_en.en_id + "/edit");
    },
    editEnduser() {
      this.dialogEnduser = true;
      this.$router.push("/boxes/" + this.row_en.en_id + "/edit");
    },
    reloadEnduser(enduserId, action) {
      if (action === "box_deleted") this.row_bo = {};
      this.$router.push("/boxes/" + this.row_en.en_id);
      // this.loadBoxeEnduser();
      // this.getParcels();
      this.loadAll();
    },
    deletedEnduser() {
      this.$router.push("/boxes");
    },
    cancelWin() {
      this.$router.push("/boxes/" + this.row_en.en_id);
    },
    cancelWinRequestAttestation() {
      this.displayFormAttestationFiscale = false;
    },
    startSSH() {
      if (window.confirm("Etes-vous certain de vouloir lancer le SSH de la box ?")) {
        //   let passphrase = prompt("Saisir le mot de passe SSH", "");
        this.$store.dispatch("socketEmit", {
          cmd: "ssh",
          data: { action: "start", bo_mac_address: this.row_bo.bo_mac_address, bo_challenge: this.row_bo.bo_challenge } //, passphrase
        });
      }
    },
    rebootBox() {
      if (window.confirm("Etes-vous certain de vouloir rebooter la box ?")) {
        this.$store.dispatch("socketEmit", {
          cmd: "reboot",
          data: { action: "reboot", bo_mac_address: this.row_bo.bo_mac_address, bo_challenge: this.row_bo.bo_challenge }
        });
      }
    },
    restartBox() {
      if (window.confirm("Etes-vous certain de vouloir redémarrer le programme principal de la box ?")) {
        this.$store.dispatch("socketEmit", {
          cmd: "restart",
          data: { action: "restart", bo_mac_address: this.row_bo.bo_mac_address, bo_challenge: this.row_bo.bo_challenge }
        });
      }
    },
    updateBox() {
      if (window.confirm("Etes-vous certain de vouloir mettre à jour le programme principal de la box ?")) {
        this.$store.dispatch("socketEmit", {
          cmd: "update",
          data: { action: "update", bo_mac_address: this.row_bo.bo_mac_address, bo_challenge: this.row_bo.bo_challenge }
        });
      }
    },
    async videoCall() {
      this.callType = "video";
      this.displayVideoCall = true;
      await this.$nextTick();
      this.dialogVideoCall = true;
      // this.$router.push("/video/" + this.row_en.en_id);
    },
    async audioCall() {
      this.callType = "audio";
      this.displayVideoCall = true;
      await this.$nextTick();
      this.dialogVideoCall = true;
      // this.$router.push("/video/" + this.row_en.en_id);
    },
    videoCallHangup() {
      this.displayVideoCall = false;
      this.$router.push("/boxes/" + this.row_en.en_id + "/edit");
    },
    async onChangeConnectionType(event) {
      //console.log(event.target.value);
      try {
        let response = await this.$axios.put(this.$config.server_url + "/extranet/2.0/contacts/" + this.row_co.co_id, {
          co_box_connection_type: event.target.value,
          co_login: this.row_co.co_login
        });
        if (response && response.data && response.data.err) {
          console.error(response.data.err);
          alert("Une erreur est survenue.");
        }
      } catch (error) {
        console.error(error);
      }
    },
    async modifySubscriptionStartDate() {
      try {
        let urlSubscriptionModification =
          this.$config.server_url + "/extranet/2.0/endusers/subscription/" + this.row_en.en_id + "/" + this.activeSubscription.id;
        if (this.activeSubscription.subscriptionData.su_gocardlessid)
          urlSubscriptionModification =
            this.$config.server_url + "/extranet/2.0/gocardless/subscription/update/" + this.row_en.en_id + "/" + this.activeSubscription.id;

        let response = await this.$axios.put(urlSubscriptionModification, {
          su_startdate: this.activeSubscription.su_startdate
        });
        if (response && response.data && response.data.err && response.data.err.key === "subscription_cancelled")
          alert("L'abonnement est annulé auprès de Gocardless, il est donc impossible de modifier la date.");
        else if (response && response.data && response.data.err) {
          console.error(response.data.err);
          this.displayModifySubscriptionStartDate = false;
          alert("Une erreur est survenue.");
          return;
        }
        this.displayModifySubscriptionStartDate = false;
        alert("Nouvelle date de démarrage d'abonnement enregistrée pour le " + this.$moment(this.activeSubscription.su_startdate).format("L"));
        await this.loadPayments();
        await this.loadBoxeEnduser();
      } catch (error) {
        console.error(error);
      }
    },
    async createSubscriptionStartDate() {
      if (!this.subscriptionMethod || !this.newSubscription.su_startdate) return;
      this.confirmStartSubscription();
    },
    modifySubscriptionStartDateDisplay() {
      this.displayModifySubscriptionStartDate = !this.displayModifySubscriptionStartDate;
    },
    displayPaymentMethodDetails(item, event) {
      this.displayPaymentDetails = true;
      this.selectedPayment = item;
    },
    closeWin(win) {
      this[win] = !this[win];
    },
    async gocardless() {
      try {
        this.disableGocardlessStartSubscriptionButton = true;
        let response = await this.$axios.post(
          this.$config.server_url + "/extranet/2.0/gocardless/subscription/start/" + this.row_en.en_id,
          this.newSubscription
        );
        this.dialogConfirmStartSubscription = false;
        this.displayConfirmStartSubscriptionDate = false;
        if (response && response.data && response.data.err) {
          this.disableGocardlessStartSubscriptionButton = false;
          let errorKey = response.data.err.key;
          if (errorKey === "subscription_exist") return alert("Un abonnement est en cours, veuillez l'annuler avant d'en démarrer un nouveau.");
          else {
            return alert("Une erreur est survenue lors du démarrage de l'abonnement.");
          }
        }
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.message === "subscription_startdate_before_mandate_possible_date_charge"
        ) {
          this.disableGocardlessStartSubscriptionButton = false;
          return alert(
            "Le mandat enregistré pour ce client sera actif à partir du " +
              response.data.data.next_possible_charge_date +
              ". Veuillez choisir une date de démarrage supérieur."
          );
        }

        await this.loadBoxeEnduser();
        await this.loadPayments();
        this.waitingSubscriptionStatus = "created";
        this.disableGocardlessStartSubscriptionButton = false;
      } catch (error) {
        console.error(error);
      }
    },
    async saveIBANToGocardless(action) {
      try {
        this.disableGocardlessButton = true;
        let response = await this.$axios.post(this.$config.server_url + "/extranet/2.0/gocardless/paymentmethod/" + action, {
          en_id: this.row_en.en_id,
          co_id: this.row_co.co_id
        });
        if (response && response.data && response.data.err) {
          alert(response.data.err.message);
          this.disableGocardlessButton = false;
          return;
        }
        if (response.data.data && response.data.data.billingData) window.open(response.data.data.billingData.authorisation_url, "_blank");
        setTimeout(() => {
          this.disableGocardlessButton = false;
        }, 5000);
      } catch (error) {
        console.error(error);
      }
    },
    waitingSpinner(display, text) {
      if (this.$refs.waitingSpinner) {
        this.$refs.waitingSpinner.changeDisplay(display, text);
      }
    },
    updateSpinner(text) {
      if (this.$refs.waitingSpinner) {
        this.$refs.waitingSpinner.addText(text);
      }
    },
    displayPaymentMethodStatus(item) {
      let status = this.paymentMethodsStatusText[item.pm_status] ? this.paymentMethodsStatusText[item.pm_status] : item.pm_status;
      if (item.pm_active && item.pm_status) return `Utilisé (${status})`;
      else if (item.pm_active) return "utilisé";
      else if (item.pm_status) return status;
      else return "";
    },
    async openFormAttestationFiscale() {
      this.displayFormAttestationFiscale = true;
    },
    async resetTv() {
      try {
        let ok = window.confirm(
          "Voulez-vous réinitialiser la TV ? Tous les messages envoyés sur cellee-ci seront supprimés et le mot de passe sera modifié."
        );
        if (ok) {
          let response = await this.$axios.post(this.$config.server_url + "/extranet/2.0/devices/reset/" + this.row_en.de_id.de_id);
          if (response && response.data && response.data.err) {
            console.error(response.data.err);
            alert("Une erreur est survenue.");
            return;
          }
          alert("La TV a été réinitialisée.");
          await this.loadAll();
        }
      } catch (error) {
        console.error(error);
      }
    },
    async deleteTv() {
      try {
        let ok = window.confirm(
          "Voulez-vous supprimer la TV ? Elle sera entièrement effacée de la base de données, ce qui implique qu'un nouveau code admin devra être renseigné sur la TV pour l'enregistrer à nouveau."
        );
        if (ok) {
          let response = await this.$axios.delete(this.$config.server_url + "/extranet/2.0/devices/tv/" + this.row_en.de_id.de_id);
          if (response && response.data && response.data.err) {
            console.error(response.data.err);
            alert("Une erreur est survenue.");
            return;
          }
          alert("La TV a été supprimée.");
          await this.loadAll();
        }
      } catch (error) {
        console.error(error);
      }
    }
  },
  updated() {
    this.$refs.popover.init();
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/styles.scss";
.container-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  border: 1px solid #b5b5b5;
  box-shadow: 2px 2px 6px 0px #d0d0d0;
}
label.m-form-checkbox {
  margin-bottom: 0 !important;
}
.fileContainer {
  height: 50px;
  background-color: #ffffff;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 0;
  //   align-items: flex-end;
  border: 1px solid #ced4da;
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
}
.btn-img {
  padding: 6px;
  display: none;
  position: absolute;
  bottom: 62px;
  width: 40px;
  height: 40px;
  //   margin-bottom: 20px;
  border: 1px solid #b7b5b5;
}
.rotate-left {
  right: 150px;
}
.rotate-right {
  right: 110px;
}
.trash {
  right: 65px;
}
.btn-rotate {
  background-color: #ffffff8f;
}
#media-to-send {
  transform-origin: left;
}
.tr-boxedetail {
  border-bottom: 1px solid #e4e4e4;
}
.mygreen {
  color: green;
  font-weight: bold;
}
.myred {
  color: red;
}
// .btn-height-auto {
//   max-height: initial;
// }
.hr-mini {
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}
.infComVsTech {
  margin-top: 10px;
}
.checkbox {
  height: 20px;
}
.linkello {
  color: #199aaa;
  font-weight: bold;
}
.logo-linkello {
  width: 100px;
}

.euro-slash.fa-icon:after {
  position: absolute;
  content: "/";
  color: red;
  font-weight: 700;
  font-size: 1.7em;
  left: 7px;
  top: -10px;
}
</style>
