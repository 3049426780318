var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("m-form-text", {
        ref: "myinput",
        class: { badNumber: _vm.badNumber },
        attrs: {
          id: _vm.id,
          value: _vm.rawvalue,
          type: "text",
          name: _vm.name,
          autocomplete: _vm.autocomplete,
          readonly: _vm.readonly,
          disabled: _vm.disabled,
          autoSelectOnFocus: false,
          label: _vm.label,
          tooltip: _vm.tooltip,
          tooltipPosition: _vm.tooltipPosition,
          rules: _vm.rules,
          placeholder: _vm.placeholder,
          appendIcon: _vm.appendIcon,
          prependIcon: _vm.prependIcon,
          appendText: _vm.appendText,
          prependText: _vm.prependText,
          ariaHaspopup: true
        },
        on: {
          click: _vm.onclick,
          input: _vm.oninput,
          focus: _vm.onfocus,
          keyup: _vm.onkeyup,
          keydown: _vm.onkeydown
        }
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showDropdown,
              expression: "showDropdown"
            }
          ],
          ref: "mydropdown",
          staticClass: "dropdown-menu",
          staticStyle: { display: "block", position: "relative" },
          on: { click: _vm.onclickDropdown }
        },
        [
          _c(
            "span",
            {
              staticClass: "float-right",
              on: {
                click: function($event) {
                  return _vm.hideOrShowDropdown(false)
                }
              }
            },
            [
              _c("icon", {
                staticClass: "mr-2",
                attrs: { name: "times-circle" }
              })
            ],
            1
          ),
          _c("m-minutes", {
            attrs: { value: _vm.value },
            on: { setTime: _vm.oninputminute }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }